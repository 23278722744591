var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('FormWrapper',{attrs:{"schema":{
              type: "object",
              "properties": {
                "no": {
                  "type": "string",
                  "title": "国内移動交通手配No",
                  "x-cols": 6,
                  "readOnly": true,
                },
                "inputDate": {
                  "type": "string",
                  "title": "申請日",
                  "format": "date",
                  "x-cols": 6,
                  "readOnly": !_vm.isAdmin
                }
              }
      }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('v-sheet',{staticClass:"pa-4",attrs:{"outlined":""}},[_c('h3',[_vm._v("担当者")]),_c('SelectUserForm',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('h3',[_vm._v("イベント詳細")]),_c('SelectEventForm',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('v-row',[_c('v-col',[_c('FormWrapper',{attrs:{"schema":{
              "type": "object",
              "required": ["role"],
              "properties": {
                "role": {
                  "type": "string",
                  "title": "お客様依頼役割",
                  "enum": ["司会・座長","講演者","その他","役なし"],
                  "x-class": "required"
                },
                "roleOther": {
                  "type": "string",
                  "title": "お客様依頼役割その他",
                  "x-if": "parent.value.role == \"その他\"",
                  "x-options": {
                    "evalMethod": "evalExpr"
                  }
                },
              }
      }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)],1),_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('h3',[_vm._v("支払内容")]),_c('FormWrapper',{attrs:{"schemaName":"TransportCostRequestDetail","disabled":_vm.disabled},model:{value:(_vm.model.requestDetail),callback:function ($$v) {_vm.$set(_vm.model, "requestDetail", $$v)},expression:"model.requestDetail"}})],1),(_vm.detail)?_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('h3',[_vm._v("お客様情報")]),_c('SelectKolForm',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('h3',[_vm._v("旅行手配内容詳細")]),_c('TravelArrangementDetails',{model:{value:(_vm.model.details),callback:function ($$v) {_vm.$set(_vm.model, "details", $$v)},expression:"model.details"}})],1),(_vm.detail)?_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('h3',{staticClass:"accent pa-2"},[_vm._v("旅行手配内容詳細（ご確定情報）")]),_c('TransportConfirm',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('h3',[_vm._v("添付書類")]),_c('DocumentsForm',{attrs:{"disabled":_vm.disabled,"itemsProp":[],"required":false},model:{value:(_vm.model.documents),callback:function ($$v) {_vm.$set(_vm.model, "documents", $$v)},expression:"model.documents"}})],1),_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('h3',[_vm._v("請求書送付先（コストセンター担当者と別の方への郵送を希望の場合には変更してください）")]),_c('SelectDestinationReceipt',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('v-sheet',{staticClass:"mt-4 pa-4",attrs:{"outlined":""}},[_c('FormWrapper',{attrs:{"schema":{
              type: "object",
              "properties": {
                "remarks": {
                  "type": "string",
                  "title": "備考",
                  "x-display": "textarea"
                },
              }
      }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }