<template>
  <v-container v-if="model">
    <v-row>
      <v-col>
        <v-autocomplete
            label="法人情報" v-model="model.companyId" :items="companies"
            item-text="name"
            item-value="id"
            dense
            :disabled="disabled"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <FormWrapper v-model="company" :schemaName="hasPhilipsAdmin ? 'Company' : 'CompanyLimited'" disabled />
    </v-row>
  </v-container>
</template>

<script>
import Rules from "@/mixins/Rules";
// import _ from "lodash"
import {mapGetters} from "vuex";
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  components: {FormWrapper},
  mixins:[Rules],
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      companies: [],
      company: null
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin', 'hasPhilipsAdmin'
    ]),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    // company() {
    //   return this.companies.find(value => value.id == this.model.companyId) || {}
    // },
    companyId(){
      return this.model.companyId
    }
  },
  watch: {
    companyId(){
      this.fetchCompany();
    }
  },
  methods: {
    async fetchCompanies() {
      let {data} = await this.$axios.get("/api/company")
      this.companies = data.content
    },
    async fetchCompany(){
      if(!this.companyId){
        this.company = {}
        return;
      }
      let {data} = await this.$axios.get(`/api/company/${this.companyId}`)
      this.company = data
    }
  },
  mounted() {
    this.fetchCompanies()
    this.fetchCompany()
  }

}
</script>