<template>
  <v-container>
    <v-sheet outlined>
      <v-container>
        <v-row>
          <v-col>
            <v-menu offset-y nudgeRight="40" fullWidth minWidth="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-on="on" readonly prepend-icon="mdi-calendar" label="請求年月"
                              v-model="yearMonth" clearable></v-text-field>
              </template>
              <v-date-picker type="month" v-model="yearMonth"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-btn @click="generate" color="primary">請求明細を自動生成する</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <FormWrapper v-model="model.invoices" schemaName="TransportInvoices" :options="options"></FormWrapper>
    </v-sheet>
  </v-container>
</template>
<script>
import FormWrapper from "@/components/requests/FormWrapper.vue";
import _ from "lodash"

export default {
  components: {
    FormWrapper
  },
  props: {
    value: Object
  },
  data() {
    return {
      menu: false,
      options: {
        // datePickerProps: {
        //   "type": "month",
        // }
      }
    }
  },
  computed: {
    model: {
      get() {
        return this.value || {}
      },
      set(v) {
        this.$emit("input", v)
      }
    },
    yearMonth: {
      get() {
        return _.get(this.model, "invoiceYearMonth")
      },
      set(v) {
        this.$set(this.model, "invoiceYearMonth", v)
      }
    }
  },
  methods: {
    generate() {
      let list = []
      let kol = _.get(this.model, "kol")
      let kolName = kol ? (kol.lastName + kol.firstName) : ""

      list = list.concat((_.get(this.model, "confirmTransport.routeDetails") || []).map(v => {
            let movingDate = _.get(this.model, "confirmTransport.movingDate")
            return {
              detail: `交通費 ${kolName}様 ${movingDate} ${v.number}`,
              paidTo: v.paidTo,
              amount: v.amount,
              paidAt: v.paidAt
            }
          }), (_.get(this.model, "confirmHotel.stayList") || []).map(v => {
            return {
              detail: `宿泊費 ${kolName}様 ${v.checkInDate} ${v.hotelName}`,
              paidTo: v.hotelName,
              amount: v.amount,
              paidAt: v.paidAt
            }
          }), ([].concat(_.get(this.model, "confirmTransport.letterPackDetail.letterPackList"),
              _.get(this.model, "confirmTaxi.letterPackDetail.letterPackList"))).map(v => {

            let paidTo = v.deliveryCompany == 'レターパック' ? "日本郵便" : v.deliveryCompany
            return {
              detail: `${v.deliveryDate} 郵送料 ${kolName}様 `,
              paidTo: paidTo,
              amount: v.deliveryCharge,
              paidAt: v.deliveryDate
            }
          })
      )

      // this.$set(this.model.invoices, "list", list)
      _.set(this.model, "invoices.list", list)
      console.log(list)
    }
  }
}
</script>