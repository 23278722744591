<template>
  <v-overlay v-model="value">
    <v-progress-circular indeterminate :size="70" :width="7"></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  props:{
    value: Boolean
  }
}
</script>
