<template>
  <v-container>
    <v-container>
      <v-row>
        <v-col>
          <FormWrapper v-model="model" :schema='{
                type: "object",
                "properties": {
                  "no": {
                    "type": "string",
                    "title": "海外移動交通手配No",
                    "x-cols": 6,
                    "readOnly": true,
                  },
                  "inputDate": {
                    "type": "string",
                    "title": "申請日",
                    "format": "date",
                    "x-cols": 6,
                    "readOnly": !isAdmin
                  }
                }
        }'></FormWrapper>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet outlined class="pa-4">
      <h3>担当者</h3>
      <SelectUserForm v-model="model"></SelectUserForm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>イベント詳細</h3>
      <SelectEventForm v-model="model"></SelectEventForm>
      <v-row>
        <v-col>
          <FormWrapper v-model="model" :schema='{
                "type": "object",
                "required": ["role"],
                "properties": {
                  "role": {
                    "type": "string",
                    "title": "お客様依頼役割",
                    "enum": ["司会・座長","講演者","その他","役なし"],
                    "x-class": "required"
                  },
                  "roleOther": {
                    "type": "string",
                    "title": "お客様依頼役割その他",
                    "x-if": "parent.value.role == \"その他\"",
                    "x-options": {
                      "evalMethod": "evalExpr"
                    }
                  },
                }
        }'></FormWrapper>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>支払内容</h3>
      <FormWrapper v-model="model.requestDetail" schemaName='TransportCostRequestDetail' :disabled="disabled"/>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4" v-if="detail">
      <h3>お客様情報</h3>
      <SelectKolForm v-model="model"></SelectKolForm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>旅行手配内容詳細</h3>
      <TravelArrangementDetails v-model="model.details" :detail="detail" isAbroad></TravelArrangementDetails>
    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4" v-if="detail">
      <h3>旅行手配内容詳細（ご確定情報）</h3>
      <TransportConfirm v-model="model"></TransportConfirm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>添付書類</h3>
      <DocumentsForm v-model="model.documents" :disabled="disabled" :itemsProp="[]" :required="false"></DocumentsForm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>請求書送付先（コストセンター担当者と別の方への郵送を希望の場合には変更してください）</h3>

      <SelectDestinationRecepit v-model="model"></SelectDestinationRecepit>

    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <FormWrapper v-model="model" :schema='{
                type: "object",
                "properties": {
                  "remarks": {
                    "type": "string",
                    "title": "備考",
                    "x-display": "textarea"
                  },
                }
        }'></FormWrapper>

    </v-sheet>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import SelectUserForm from "@/components/requests/SelectUserForm";
import SelectEventForm from "@/components/requests/SelectEventForm";
import SelectDestinationRecepit from "@/components/requests/SelectDestinationReceipt";

import vjsfOptions from "@/plugins/vjsfOptions"
import TravelArrangementDetails from "@/pages/requests/transport_cost/TravelArrangementDetails";
import FormWrapper from "@/components/requests/FormWrapper.vue";
import SelectKolForm from "@/components/requests/SelectKolForm.vue";
import TransportConfirm from "@/pages/requests/transport_cost/TransportConfirm.vue";
import DocumentsForm from "@/components/requests/DocumentsForm.vue";


export default {
  components: {
    TravelArrangementDetails,
    SelectDestinationRecepit,
    SelectEventForm,
    SelectUserForm,
    FormWrapper,
    SelectKolForm,
    TransportConfirm,
    DocumentsForm
  },
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formData: undefined,
    valid: undefined,
    vjsfOptions
  }),
  computed: {
    ...mapGetters([
      'apiDoc'
    ]),
    schema() {
      return this.apiDoc ? this.apiDoc.components.schemas[this.schemaName] : {}
    },
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
  }
}
</script>
<style>
.border-1 {
  border: solid 1px #eeeeee
}
</style>