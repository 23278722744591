<template>
    <v-container>
        <h1>CSVダウンロード</h1>
        <v-sheet outlined class="border-1 pa-2 ma-2">
            <v-form>
                申請一覧CSV
                <FormWrapper :schema="dateSchema" v-model="model0"></FormWrapper>
                <v-btn @click.stop="download('all_requests', model0)">ダウンロード</v-btn>
            </v-form>
        </v-sheet>
        <v-sheet outlined class="border-1 pa-2 ma-2">
            <v-form>
                法人宛支払依頼CSV
                <v-text-field v-model="model1.companyName" label="法人名"></v-text-field>
                <FormWrapper :schema="dateSchema" v-model="model1"></FormWrapper>
                <v-btn @click.stop="download('company_requests', model1)">ダウンロード</v-btn>
            </v-form>
        </v-sheet>
        <v-sheet outlined class="border-1 pa-2 ma-2">
            <v-form>
                お客様支払依頼CSV
                <FormWrapper :schema="dateSchema" v-model="model2"></FormWrapper>
                <v-btn @click.stop="download('kol_requests', model2)">ダウンロード</v-btn>
            </v-form>
        </v-sheet>
        <v-sheet outlined class="border-1 pa-2 ma-2">
            <v-form>
                交通費立替精算CSV
                <FormWrapper :schema="dateSchema" v-model="model3"></FormWrapper>
                <v-btn @click.stop="download('travel_cost_requests', model3)">ダウンロード</v-btn>
            </v-form>
        </v-sheet>
    </v-container>
</template>
<script>
import FormWrapper from "@/components/requests/FormWrapper.vue";

const dateSchema = {
    type: "object",
    "properties": {
        "inputDate": {
            "type": "string",
            "title": "申請日",
            "x-cols": 6,
            "format": "date"
        },
        "arrangementCompletedDate": {
            "type": "string",
            "title": "支払日",
            "x-cols": 6,
            "format": "date"
        },
        "inputDateFrom": {
            "type": "string",
            "title": "申請日From",
            "x-cols": 6,
            "format": "date"
        },
        "inputDateTo": {
            "type": "string",
            "title": "申請日To",
            "x-cols": 6,
            "format": "date"
        },
        "arrangementCompletedDateFrom": {
            "type": "string",
            "title": "支払日From",
            "x-cols": 6,
            "format": "date"
        },
        "arrangementCompletedDateTo": {
            "type": "string",
            "title": "支払日To",
            "x-cols": 6,
            "format": "date"
        },
    }
}

export default {
    components: {
        FormWrapper
    },
    data() {
        return {
            dateSchema,
            model0: {},
            model1: {},
            model2: {},
            model3: {},
        }
    },
    methods: {
        async download(resource, model) {
            const queryString = new URLSearchParams(model).toString();
            let url = `/api/export/${resource}?${queryString}`
            window.open(url, '_blank')
        }
    }
}
</script>