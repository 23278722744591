<template>
  <v-container>
    <h1>イベント情報の詳細</h1>

    <v-card class="text-center pa-5 detail-table" outlined elevation="1">
      <v-card-text>
        <v-form ref="form">
          <v-row class="text-center">
            <v-col cols="12">
              <FormWrapper v-model="model" schemaName="DocEvent">
                <template slot="eventName-before">
                  <h3 class="text-left my-5 pl-3" style="font-size: 18px; border-left: solid 5px" >開催情報</h3>
                </template>
                <template slot="venueName-before">
                  <h3 class="text-left my-5 pl-3" style="font-size: 18px; border-left: solid 5px" >会場情報</h3>
                </template>
                <template slot="organizerName-before">
                  <h3 class="text-left my-5 pl-3" style="font-size: 18px; border-left: solid 5px" >主催・連絡先情報（書類の提出者・事業部責任者、イベントの担当者/連絡先）</h3>
                  <h4 class="text-left my-5 pl-3" style="font-size: 18px;" >書類の提出者・事業部責任者</h4>
                </template>
                <template slot="managementCompanyName-before">
                  <h4 class="text-left my-5 pl-3" style="font-size: 18px;" >イベントの担当者/連絡先</h4>
                </template>
              </FormWrapper>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.stop="save" color="primary" v-if="!readonly">保存</v-btn>
        <v-btn :to="`/doc_event/${eventId}`">戻る</v-btn>
      </v-card-actions>
    </v-card>

  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  components: {FormWrapper},
  props: {
    eventId: String
  },
  data() {
    return {
      model: null,
      readonly: false
    }
  },
  computed: {
    ...mapGetters([
      'apiDoc',
      'getSchema'
    ]),
    schema() {
      return this.getSchema("DocEvent")
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      let {data} = await this.$axios.get(`/api/doc_event/${this.eventId}`)
      this.model = data
    },
    async save(){
      if (!this.$refs.form.validate()) {
        return
      }
      await this.$axios.put(`/api/doc_event/${this.eventId}`,this.model)
      window.bus.$emit('toast', '保存しました')
    },
    async close(){
      this.modalOpen = false
    }
  }
}
</script>