<template>
  <v-container class="pa-0">
    <Loading v-model="loading"></Loading>
    <v-form :valid="valid" ref="form">
      <v-sheet class="border-1 pa-2 ma-2">
        <v-container v-if="isAdmin && !isNew" class="text-right">
          <v-btn color="error" @click.stop="deleteItem">削除</v-btn>
          <v-btn color="error" @click.stop="withdraw" class="ml-2">取り下げ</v-btn>
        </v-container>

        <slot :model="model" :isNew="isNew" :isAdmin="isAdmin"></slot>

        <v-sheet outlined class="mt-4 pa-4" v-if="isNew">
          <h3>2重払いを防止するため，以下の内容をご確認ください</h3>
          <v-checkbox label="添付書類に間違いがないかを確認しました。" :rules="[rules.required]"></v-checkbox>
          <v-checkbox label="過去に登録していない情報です。" :rules="[rules.required]"></v-checkbox>
        </v-sheet>

        <v-sheet class="mt-4 pa-4">
          <template v-if="isNew">
            <v-btn @click.stop="save" color="info">一時保存</v-btn>
            <v-btn @click.stop="entry" color="primary">申請</v-btn>
          </template>
          <template v-else>
            <v-btn @click.stop="save" color="primary" v-if="isAdmin">保存</v-btn>
          </template>
        </v-sheet>
      </v-sheet>
    </v-form>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import Loading from "@/components/Loading";
import Rules from "@/mixins/Rules.vue";
import _ from "lodash"
import dayjs from 'dayjs'

export default {
  mixins: [Rules],
  props: {
    id: String,
    resource: String,
    listPage: String,
    value: Object
  },
  components: {
    Loading
  },
  data: () => ({
    loading: false,
    valid: undefined,
  }),
  computed: {
    ...mapGetters([
      'apiDoc', 'isAdmin', 'me'
    ]),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    isNew() {
      return !_.has(this.model, "no") || this.model.status == "一時保存"
    }
  },
  async created() {
    if(this.id){
      await this.fetch()
    }else{
      this.model = {
        userId: this.me.id
      }
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        let {data} = await this.$axios.get(`/api/request/${this.resource}/${this.id}`)
        this.model = data
      } catch (e) {
        window.bus.$emit('error', '指定した内容は見つかりません')
      }
      this.loading = false
    },
    async entry() {
      if (!_.get(this.model, "inputDate")) {
        this.model.inputDate = dayjs().format('YYYY-MM-DD')
      }
      await this.save()
    },
    async save() {
      if (!this.$refs.form.validate()) {
        window.bus.$emit('error', '入力内容に不備があります')
        return
      }
      try {
        if (this.id) {
          await this.$axios.put(`/api/request/${this.resource}/${this.id}`, this.model)
        } else {
          await this.$axios.post(`/api/request/${this.resource}`, this.model)
        }
        window.bus.$emit('toast', '保存しました')
        await this.$router.push(`/${this.listPage}`)
      } catch (e) {
        let message = _.get(e, "response.data.userMessage", "保存に失敗しました")
        window.bus.$emit('error', message)
      }
    },
    async deleteItem() {
      try {
        if (!confirm("本当に削除しますか？")) return
        await this.$axios.delete(`/api/request/${this.resource}/${this.id}`)
        window.bus.$emit('toast', '削除しました')
        await this.$router.push(`/${this.listPage}`)
      } catch (e) {
        window.bus.$emit('error', '削除に失敗しました')
      }
    },
    async withdraw() {
      try {
        if (!confirm("本当に取り下げますか？")) return
        await this.$axios.post(`/api/request/${this.resource}/withdraw/${this.id}`)
        window.bus.$emit('toast', '取り下げました')
        await this.$router.push(`/${this.listPage}`)
      } catch (e) {
        window.bus.$emit('error', '取り下げに失敗しました')
      }
    }
  }
}
</script>
