<template>
  <v-container>
    <h1>招聘状出力</h1>

    <VJsf v-model="model" :schema='{
                type: "object",
                "properties": {
                  "template": {
                    "type": "string",
                    "title": "招聘状",
                    "x-fromUrl": "/api/letters/templates",
                    "x-itemKey": "value",
                    "x-itemTitle": "label"
                  }
                }
        }'></VJsf>
    <div class="pa-5" style="border: solid 1px; width: auto;display: inline-block">
      <iframe
          width="840px"
          height="1800px"
          scrolling="no"
          frameborder="0"
          :srcdoc="srcdoc"
          ref="program_frame"
          style="height: 1800px;"></iframe>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  data() {
    return {
      model: {},
      srcdoc: null
    }
  },
  async created() {
  },
  methods: {
    async fetch() {
      if(!this.template) return
      try{
        let {data} = await axios.get(`/api/letters/${this.template}`);
        this.srcdoc = data
      }catch (e) {
        this.srcdoc = ""
      }
    }
  },
  computed: {
    template() {
      return _.get(this.model, "template")
    }
  },
  watch: {
    template() {
      this.fetch()
    }
  }
}
</script>
