<template>
  <CRUDPage
      searchSchemaName="InvoiceSearchParamsAbstractRequest"
      :resultHeaders="resultHeaders"
      resource="invoice"
      title="請求書発行一覧"
      schemaName="Invoice"
      disableServerPaging
      :searchParamsProp="searchParamsProp"
  >
    <template v-slot:top>
    </template>
    <template v-slot:item.po.no="{item}">
      {{ item.po.no }}<br/>
      {{ item.po.id }}
    </template>
    <template v-slot:item.invoice="{item}">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              small
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            請求書確認
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="i in invoiceList" :key="i.label"
                       @click="toPdf(item, i.value)"
                       :disabled="isDisabled(item, i.value)"
          >
            <v-list-item-title>{{ i.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:item.actions="{item}">
      <ApprovalButton :item="item"></ApprovalButton>
    </template>
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
// import _ from "lodash"
import dayjs from "dayjs"
import ApprovalButton from "@/components/ApprovalButton.vue";

export default {
  components: {CRUDPage, ApprovalButton},
  props: {
    poId: String,
    userId: String,
    yearMonth: {
      type: String,
      "default": dayjs().format("YYYY-MM")
    }
  },
  data() {
    return {
      resultHeaders: [
        {text: '対象年月', value: 'yearMonth'},
        {text: 'コストセンター', value: 'po.costCenter.name'},
        {text: 'PO-No\nPOシステムID', value: 'po.no'},
        {text: '送付先', value: 'destinationUser.name'},
        {text: '支払\n依頼件数', value: 'totalRequestNum'},
        {text: '請求金額', value: 'totalAmount'},
        // {text: '請求書発行', value: 'invoice'},
        // {text: '状況', value: 'status'},
        {
          text: '請求書発行',
          value: 'invoice',
          sortable: false
        },
        {
          text: '状況',
          value: 'actions',
          sortable: false
        },
      ],
      invoiceList: [
        {label: "ALL", value: "All"},
        {label: "法人支払依頼", value: "CompanyRequest"},
        {label: "お客様支払依頼", value: "KolRequest"},
        {label: "交通費立替清算", value: "TravelCostRequest"},
        {label: "国内移動交通手配", value: "TransportCostRequest"},
        {label: "海外移動交通手配", value: "TransportCostAbroadRequest"},
        {label: "国内タクシーチケット", value: "TaxiTicket"},
        {label: "海外タクシーチケット", value: "TaxiTicketAbroad"},
        {label: "交通費立替清算レターパック", value: "LetterPackTravelCost"},
        {label: "お客様支払依頼レターパック", value: "LetterPackKol"},
      ],
      searchParamsProp: {
        poId: parseInt(this.poId) || null,
        userId: parseInt(this.userId) || null,
        yearMonth: this.yearMonth
      }
    }
  },
  methods: {
    isDisabled(item, v) {
      if (v == "All") return false
      return !(item.keyMap || []).includes(v)
    },
    toPdf(item, v) {
      console.log(item)
      window.open(`/api/invoice/pdf/${item.key.poId}/${item.key.userId}/${item.key.month}/${v}`, "_blank")
    },
  }
}
</script>
