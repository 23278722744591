<template>
  <v-container>
    <h3>送付内容</h3>
    <FormWrapper v-model="model.letterPackDetail" schemaName='LetterPackDetail' :disabled="disabled"/>
  </v-container>
</template>

<script>
import Rules from "@/mixins/Rules";
// import _ from "lodash"
import {mapGetters} from "vuex";
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  components: {FormWrapper},
  mixins: [Rules],
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin'
    ]),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
  }
}
</script>
