<template>
  <DataRestTable v-bind="$props" :optionsProp="{
        page: 1,
        itemsPerPage: 20,
        sortBy: ['id'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
  }">
    <template v-slot:item.id="{item}">
      {{ item.no }}
    </template>
    <template v-slot:item.user.lastName="{item}">
      {{ item.user && item.user.name }}
    </template>
    <template v-slot:item.event.name="{item}">
      {{ lodash.get(item, "event.name") }}<br/>
      {{ item.requestContents }}
    </template>

    <template v-slot:item.bank="{item}">
      <td>
        <v-chip x-small color="primary" v-if="item.kol && item.kol.registeredBankAccount">登録済み</v-chip>
        <v-chip x-small color="error" v-else>未登録</v-chip>
        <v-chip x-small color="primary" v-if="item.kol && item.kol.isRegisteredMyNumber == '登録済み'">登録済み</v-chip>
        <v-chip x-small color="error" v-else>未登録</v-chip>
      </td>
    </template>

    <template v-slot:item.transport="{item}">
      <td>
        <v-chip x-small color="primary" v-if="lodash.get(item, 'costConfirm.confirmTransport.hasArranged')">手配済
        </v-chip>
        <v-chip x-small v-else-if="(lodash.get(item, 'details.requiredDetailTypes')|| []).includes('交通手配')">有
        </v-chip>
      </td>
    </template>
    <template v-slot:item.hotel="{item}">
      <td>
        <v-chip x-small color="primary" v-if="lodash.get(item, 'costConfirm.confirmHotel.hasArranged')">手配済</v-chip>
        <v-chip x-small v-else-if="(lodash.get(item, 'details.requiredDetailTypes')|| []).includes('宿泊手配')">有
        </v-chip>
      </td>
    </template>
    <template v-slot:item.taxi="{item}">
      <td>
        <v-chip x-small color="primary" v-if="lodash.get(item, 'costConfirm.confirmTaxi.hasArranged')">手配済</v-chip>
        <v-chip x-small v-else-if="(lodash.get(item, 'details.requiredDetailTypes')|| []).includes('タクシーチケット')">有
        </v-chip>
      </td>
    </template>
    <template v-slot:item.statuses.inputDate="{item}">
      <template v-if="item.withdrawnDate">
        <v-chip x-small color="error">取り下げ</v-chip>
      </template>
      <template v-else-if="item.inputDate">
        <v-chip x-small color="primary">申請済み</v-chip>
        <br/>
        {{ item.inputDate }}
      </template>
      <template v-else>
        <v-chip x-small>一時保存</v-chip>
      </template>
    </template>
    <template v-slot:item.statuses.registerDate="{item}">
      <template v-if="item.defectDate">
        <v-chip x-small color="error">申請不備あり</v-chip>
        <br/> {{ item.defectDate }}
      </template>
      <v-chip x-small color="accent" v-else-if="item.registerDate">申請内容確認済</v-chip>
      <v-chip x-small v-else-if="item.inputDate">申請内容確認中</v-chip>
      <br/>
      {{ item.registerDate }}
    </template>
    <template v-slot:item.statuses.scheduledPaymentDate="{item}">
      <v-chip x-small color="secondary" v-if="item.scheduledPaymentDate">支払予定</v-chip>
      <br/>
      {{ item.scheduledPaymentDate }}
    </template>
    <template v-slot:item.statuses.arrangementCompletedDate="{item}">
      <v-chip x-small color="primary" v-if="item.arrangementCompletedDate">支払済</v-chip>
      <br/>
      {{ item.arrangementCompletedDate }}
    </template>

    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props"/>
    </template>
  </DataRestTable>
</template>
<script>
import DataRestTable from "@/components/DataRestTable.vue";
import lodash from "lodash"

export default {
  components: {DataRestTable},

  props: {
    resource: {
      type: String
    },
    searchParams: Object,
    disableServerPaging: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array
    }
  },
  data() {
    return {
      lodash
    }
  }
}
</script>