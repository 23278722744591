<template>
  <div>


  <CRUDPage
      :resultHeaders="resultHeaders"
      resource="transparency/master"
      title="透明性GLマスタ一覧"
      schemaName="TransparencyGlMasterDto"
  >
    <template v-slot:pageheader>
      <TransparencyMenu></TransparencyMenu>
    </template>

    <template v-slot:item.code2="{item}">
      <template v-if="item.code2">
        {{item.code2.code2}} {{item.code2.nameEn}}<br />
        {{item.code2.nameJa}}
      </template>
    </template>

  </CRUDPage>
  </div>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import TransparencyMenu from "@/pages/master/transparency/TransparencyMenu.vue";

export default {
  components: {TransparencyMenu, CRUDPage},
  data() {
    return {
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '支払申請種類', value: 'requestType'},
        {text: '支払依頼内容', value: 'requestContentType'},
        {text: '透明性GLコード2', value: 'code2'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
