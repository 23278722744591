var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-5"},[_c('h1',[_vm._v("HOME")]),_c('section',[_c('v-card',{attrs:{"max-width":"800","shaped":"","elevation":"2"}},[_c('v-toolbar',{attrs:{"color":"grey","dark":"","dense":""}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-information")]),_c('v-toolbar-title',[_vm._v("お知らせ一覧")])],1),_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.infos),function(item,i){return _c('v-list-item',{key:i,attrs:{"two-line":"","to":`/info/${item.id}`}},[_c('v-list-item-content',[_c('v-list-item-title',[(_vm.isNew(item))?_c('span',{staticClass:"red--text caption"},[_vm._v("NEW")]):_vm._e(),_vm._v(" "+_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("dateFormat")(item.sendDateTime)))])],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"plain":"","to":"/info"}},[_vm._v("もっとみる")])],1)],1)],1),_c('section',[_c('h2',[_vm._v("各種依頼申請")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.links.add.items),function(item,index){return _c('LinkButton',{key:index,attrs:{"item":item}})}),1)],1),_c('section',[_c('h2',[_vm._v("申請一覧")]),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.links.list.items),function(item,index){return _c('LinkButton',{key:index,attrs:{"item":item}})}),1)],1),_c('section',[_c('h2',[_vm._v("連携システム")]),_c('v-row',{attrs:{"dense":""}},[_c('LinkButton',{attrs:{"item":{
                  title: '会場予約システム',
                  href: 'https://www.secure-cloud.jp/sf/business/1668128117TZdVnYoG',
                  target: '_blank',
                  icon: 'mdi-domain',
                  color: 'teal'
        }}}),_c('LinkButton',{attrs:{"item":{
                  title: 'イベント書類作成システム',
                  to: '/doc_event',
                  icon: 'mdi-domain',
                  color: 'secondary'
        }}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }