<template>
  <v-card class="text-center pa-5 detail-table" outlined elevation="1">
    <v-card-text>
      <v-form :valid="valid" ref="form">
        <v-row class="text-center">
          <v-col cols="12">
            <VJsf v-model="model" :schema="schema" :options="options">
              <template v-for="(_, slot) in $slots">
                <template :slot="slot">
                  <slot :name="slot"></slot>
                </template>
              </template></VJsf>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.stop="save" color="primary" v-if="!readonly">保存</v-btn>
      <v-btn @click.stop="deleteItem" color="error" v-if="!readonly && model && model.id">削除</v-btn>
      <v-btn @click.stop="close">閉じる</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import vjsfOptions from "@/plugins/vjsfOptions"

export default {
  props: {
    schema: Object,
    value: {type: Object},
    readonly: Boolean
  },
  data() {
    return {
      valid: false,
      vjsfOptions
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    options() {
      return Object.assign({}, vjsfOptions, {
        rules: {
          email: v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスが正しくありません',
          password: v => {
            if (!v) return true;
            return /(?=.*[A-Za-z])(?=.*[0-9])[a-zA-Z0-9@$#\-_]{8,100}/.test(v) || "英数両方を含めて8文字以上で入力してください"
          },
          passwordConfirm: v => {
            if (!v && !this.model.passwordCreate) return true;
            return v == this.model.passwordCreate || "パスワードが一致しません"
          }
        },
        disableAll: this.readonly
      })
    }
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit("save", this.model)
    },
    deleteItem() {
      if (confirm("削除しますか？")) {
        this.$emit("delete", this.model.id)
      }
    },
    close() {
      this.$emit("close")
    }
  }
}
</script>
<style>
.detail-table .v-input {
  padding-right: 5px;
  padding-left: 5px;
}

.required label::after {
  content: "*";
}

div[aria-required=true].v-input .v-label::after {
  content: " *";
  color: red;
}
</style>

