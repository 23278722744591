<template>
  <v-container>
          <FormWrapper v-model="model" :schema='{
                type: "object",
                "properties": {
                  "no": {
                    "type": "string",
                    "title": "お客様支払依頼申請No",
                    "x-cols": 6,
                    "readOnly": true,
                  },
                  "inputDate": {
                    "type": "string",
                    "title": "申請日",
                    "format": "date",
                    "x-cols": 6,
                    "readOnly": !isAdmin
                  }
                }
        }'></FormWrapper>

    <v-sheet outlined class="pa-4">
      <h3>担当者</h3>
      <SelectUserForm v-model="model"></SelectUserForm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>イベント詳細</h3>
      <SelectEventForm v-model="model"></SelectEventForm>
          <FormWrapper v-model="model" :schema='{
                "type": "object",
                "required": ["role"],
                "properties": {
                  "role": {
                    "type": "string",
                    "title": "お客様依頼役割",
                    "enum": ["司会・座長","講演者","その他","役なし"],
                    "x-class": "required"
                  },
                  "roleOther": {
                    "type": "string",
                    "title": "お客様依頼役割その他",
                    "x-if": "parent.value.role == \"その他\"",
                    "x-options": {
                      "evalMethod": "evalExpr"
                    }
                  },
                }
        }'></FormWrapper>
    </v-sheet>


    <v-sheet outlined class="mt-4 pa-4">
      <h3>支払依頼内容</h3>
      <SelectRequestContents v-model="model" url="/api/request/kol/types"></SelectRequestContents>
      <SelectTransparencyGlCodeFields v-model="model" :disabled="disabled"></SelectTransparencyGlCodeFields>
    </v-sheet>


    <v-sheet outlined class="mt-4 pa-4">
      <h3>支払内容</h3>
      <FormWrapper v-model="model.requestDetail" schemaName='KolRequestDetail' :disabled="disabled" />
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4" v-if="detail">
      <h3>お客様情報</h3>
      <SelectKolForm v-model="model"></SelectKolForm>
    </v-sheet>


    <v-sheet outlined class="mt-4 pa-4">
      <h3>添付書類</h3>
      <DocumentsForm v-model="model.documents" :disabled="disabled"
      :itemsProp="['招聘状・依頼応諾書','請求書','領収書','その他']"
      ></DocumentsForm>
    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <h3>支払額内訳</h3>
      <p class="red--text">支払額はTKPで入力いたします。</p>
      <PaymentDetail v-model="model.paymentDetail" schemaName='PaymentDetail' :disabled="!isAdmin"/>

    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <h3>請求書送付先（コストセンター担当者と別の方への郵送を希望の場合には変更してください）</h3>
      <SelectDestinationRecepit v-model="model"></SelectDestinationRecepit>
    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <v-textarea v-model="model.remarks" label="備考" :disabled="disabled"></v-textarea>
    </v-sheet>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import SelectUserForm from "@/components/requests/SelectUserForm";
import SelectEventForm from "@/components/requests/SelectEventForm";
import SelectDestinationRecepit from "@/components/requests/SelectDestinationReceipt";

import vjsfOptions from "@/plugins/vjsfOptions"
import SelectRequestContents from "@/components/requests/SelectRequestContents";
import DocumentsForm from "@/components/requests/DocumentsForm";
import FormWrapper from "@/components/requests/FormWrapper.vue";
import PaymentDetail from "@/pages/requests/common/PaymentDetail.vue";
import SelectKolForm from "@/components/requests/SelectKolForm.vue";
import SelectTransparencyGlCodeFields from "@/components/requests/SelectTransparencyGlCodeFields.vue";


export default {
  components: {
    SelectTransparencyGlCodeFields,
    PaymentDetail,
    FormWrapper,
    SelectDestinationRecepit,
    SelectEventForm,
    SelectUserForm,
    SelectRequestContents,
    DocumentsForm,
    SelectKolForm
  },
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formData: undefined,
    valid: undefined,
    vjsfOptions
  }),
  computed: {
    ...mapGetters([
      'apiDoc', 'isAdmin'
    ]),
    schema() {
      return this.apiDoc ? this.apiDoc.components.schemas[this.schemaName] : {}
    },
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    getSchema(name) {
      return this.apiDoc ? this.apiDoc.components.schemas[name] : {}
    },
    async save() {
      // if(val)
      try {
        await this.$axios.post(`/api/request/company/`, this.model)
        window.bus.$emit('toast', '保存しました')
      } catch (e) {
        window.bus.$emit('error', '保存に失敗しました')
      }
    }
  }
}
</script>
<style>
.border-1 {
  border: solid 1px #eeeeee
}
</style>