<template>
<!--  <v-currency-field-->
<!--    v-bind="$props" v-model="model"-->
<!--    prefix="¥"-->
<!--  />-->
  <v-currency-field
      v-bind="$props" v-model="model"
      prepend-inner-icon="mdi-currency-jpy"
  />
<!--  -->
</template>
<script>
export default {
  props: {
    value: {type: Number, default: null},
    options: {type: Object, required: true},
    schema: {type: Object, required: true},
    fullSchema: {type: Object, required: true},
    fullKey: {type: String, required: true},
    label: {type: String, default: '金額'},
    htmlDescription: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    rules: {type: Array, required: true},
    on: {type: Object, required: true}
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.on.input(v)
      }
    },
  }
}
</script>
<style>
.v-input__icon--prepend-inner .mdi-currency-jpy {
  font-size:16px;
}
</style>