<template>
  <v-container>
    <v-card v-if="!message">
      <v-card-title>新規作成</v-card-title>
      <v-form ref="form">
        <FormWrapper schemaName="SignUpDto" v-model="model" :options="options"></FormWrapper>
      </v-form>
      <v-card-actions>
        <v-btn @click.stop="submit" color="primary" text>登録</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text>{{message}}</v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import FormWrapper from "@/components/requests/FormWrapper.vue";
import _ from "lodash"
import Rules from "@/mixins/Rules.vue";

export default {
  mixins:[Rules],
  components: {
    FormWrapper
  },
  data() {
    return {
      model: {},
      message: null
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        window.bus.$emit('error', '入力内容に不備があります')
        return
      }
      try {
        await this.$axios.post("/auth/signup", this.model)
        window.bus.$emit('toast', '仮登録メールを送信しました')
        this.message = "仮登録メールを送信しました"
      } catch (e) {
        window.bus.$emit('error', '登録に失敗しました')
      }
    }
  },
  computed: {
    email() {
      return _.get(this.model, "email")
    },
    options(){
      return {
        fieldColProps:{
          rules:this.rules
        }
      }
    }
  },
  watch: {
    email(val) {
      this.$set(this.model, "loginId", val)
    }
  }
}
</script>