<template>
  <v-menu offset-y nudgeRight="40" fullWidth minWidth="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-on="on" readonly prepend-icon="mdi-calendar" :label="label"
                    v-model="model"></v-text-field>
    </template>
    <v-date-picker type="month" v-model="model"></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {type: Object, default: null},
    options: {type: Object, required: true},
    schema: {type: Object, required: true},
    fullSchema: {type: Object, required: true},
    fullKey: {type: String, required: true},
    label: {type: String, default: '年月'},
    htmlDescription: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    rules: {type: Array, required: true},
    on: {type: Object, required: true}
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.on.input(v)
      }
    },
  }
}
</script>