<template>
  <RequestsDetail resource="transport_cost_abroad" listPage="transport_cost_abroad_requests" v-model="model" :id="id">
    <template v-slot:default="{isNew, isAdmin}">
      <h1>海外移動交通手配申請</h1>

      <TransportCostAbroadRequestsForm v-model="model" :disabled="!isNew && !isAdmin" :detail="!isNew"></TransportCostAbroadRequestsForm>

      <TKPExclusiveForm v-model="model" :disabled="!isNew &&!isAdmin" v-if="!isNew" transport></TKPExclusiveForm>
      <TransportInvoices v-model="model" :disabled="!isNew &&!isAdmin" v-if="!isNew"></TransportInvoices>
    </template>
  </RequestsDetail>
</template>
<script>
import RequestsDetail from "@/pages/requests/common/RequestsDetail.vue";
import TKPExclusiveForm from "@/components/requests/TKPExclusiveForm.vue";
import TransportCostAbroadRequestsForm
  from "@/pages/requests/transport_cost_abroad/TransportCostAbroadRequestsForm.vue";
import TransportInvoices from "@/pages/requests/common/TransportInvoices.vue";

export default {
  props: {
    id: String,
  },
  data() {
    return {
      model: {}
    }
  },
  components: {
    TransportCostAbroadRequestsForm,
    TKPExclusiveForm,
    RequestsDetail,
    TransportInvoices
  },
}
</script>
