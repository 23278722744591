<template>
  <v-container>
    <FormWrapper v-model="model" :schema='{
                type: "object",
                "properties": {
                  "no": {
                    "type": "string",
                    "title": "法人宛支払依頼申請No",
                    "x-cols": 6,
                    "readOnly": true,
                  },
                  "inputDate": {
                    "type": "string",
                    "title": "申請日",
                    "format": "date",
                    "x-cols": 6,
                    "readOnly": !isAdmin
                  },
                }
        }'></FormWrapper>
    <v-sheet outlined class="pa-4">
      <h3>担当者</h3>
      <SelectUserForm v-model="model" @validate="$emit('validate')" :disabled="disabled"></SelectUserForm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>イベント詳細</h3>
      <SelectEventForm v-model="model" :disabled="disabled" emptyDateEnable></SelectEventForm>
    </v-sheet>


    <v-sheet outlined class="mt-4 pa-4">
      <h3>支払依頼内容</h3>
      <SelectRequestContents v-model="model" :disabled="disabled"></SelectRequestContents>
      <SelectTransparencyGlCodeFields v-model="model" :disabled="disabled"></SelectTransparencyGlCodeFields>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>支払内容</h3>

      <FormWrapper v-model="model.requestDetail" schemaName='CompanyRequestDetail' :disabled="disabled"/>
    </v-sheet>


    <v-sheet outlined class="mt-4 pa-4" v-if="detail">
      <h3>法人情報</h3>
      <SelectCompanyForm v-model="model" :disabled="disabled"></SelectCompanyForm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>添付書類</h3>
      <DocumentsForm v-model="model.documents" :disabled="disabled"></DocumentsForm>
    </v-sheet>

    <v-sheet outlined class="mt-4 pa-4">
      <h3>支払額内訳</h3>
      <p class="red--text">支払額はTKPで入力いたします。</p>
      <PaymentDetail v-model="model.paymentDetail" schemaName='PaymentDetail' :disabled="!isAdmin"/>

    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <h3>請求書送付先（コストセンター担当者と別の方への郵送を希望の場合には変更してください）</h3>
      <SelectDestinationReceipt v-model="model" :disabled="disabled"></SelectDestinationReceipt>

    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <v-textarea v-model="model.remarks" label="備考" :disabled="disabled"></v-textarea>
    </v-sheet>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import SelectUserForm from "@/components/requests/SelectUserForm";
import SelectEventForm from "@/components/requests/SelectEventForm";
import SelectRequestContents from "@/components/requests/SelectRequestContents";
import DocumentsForm from "@/components/requests/DocumentsForm";
import SelectDestinationReceipt from "@/components/requests/SelectDestinationReceipt";

import vjsfOptions from "@/plugins/vjsfOptions"
import _ from "lodash";
import FormWrapper from "@/components/requests/FormWrapper.vue";
import PaymentDetail from "@/pages/requests/common/PaymentDetail.vue";
import SelectCompanyForm from "@/components/requests/SelectCompanyForm.vue";
import SelectTransparencyGlCodeFields from "@/components/requests/SelectTransparencyGlCodeFields.vue";


export default {
  components: {
    SelectTransparencyGlCodeFields,
    SelectCompanyForm,
    PaymentDetail,
    FormWrapper,
    SelectUserForm,
    SelectEventForm,
    SelectRequestContents,
    DocumentsForm,
    SelectDestinationReceipt,
  },
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formData: undefined,
    schemaName: "CompanyRequestCreateBody",
    valid: undefined,
    vjsfOptions
  }),
  computed: {
    ...mapGetters([
      'apiDoc', 'isAdmin', 'getSchema'
    ]),
    schema() {
      return this.apiDoc ? this.apiDoc.components.schemas[this.schemaName] : {}
    },
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    inputDate() {
      return _.get(this.model, "inputDate")
    },
    requestDetailSchema() {
      return this.getSchema("CompanyRequestDetail");
    }
  },
  methods: {
    async save() {
      // if(val)
      try {
        await this.$axios.post(`/api/request/company/`, this.model)
        window.bus.$emit('toast', '保存しました')
      } catch (e) {
          window.bus.$emit('error', '保存に失敗しました')
        }
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {

      }
    }
  }
}
</script>
<style>
.border-1 {
  border: solid 1px #eeeeee
}
</style>