<template>
  <v-container>
    <h1>{{ title }}</h1>

    <slot name="pageheader"></slot>

    <SearchTable
        v-if="searchSchemaInner"
        :schema="searchSchemaInner"
        @search="search"
        v-model="searchTableParams"
    ></SearchTable>

    <DataRestTable
        v-model="tableSelected"
        :headers="resultHeaders"
        :resource="resource"
        :searchParams="searchParams"
        ref="dataTable"
        :disableServerPaging="disableServerPaging"
    >
      <template v-slot:top>
        <v-btn color="primary" @click="add()" v-if="!readonly">新規作成</v-btn>
<!--        <v-btn color="error" @click="add()" disabled>削除</v-btn>-->
      </template>
      <template v-slot:item.actions="{item}">
        <td>
          <v-btn color="primary" @click="select(item.id)" v-if="item">詳細</v-btn>
        </td>
      </template>
      <template v-for="(_, slot) in $slots">
        <template :slot="slot">
          <slot :name="slot"></slot>
        </template>
      </template>
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </DataRestTable>

    <v-dialog
        v-model="modalOpen"
        v-if="modalOpen"
        persistent
        max-width="800px"
    >
        <DetailTable :schema="schema" v-model="model" v-if="modalOpen"
                     v-show="model"
                     @save="save" @close="close" @delete="deleteItem"
                     :readonly="readonly"
        >

          <template v-for="(_, slot) in $slots" >
            <template :slot="slot" v-if="slot.endsWith('before')">
              <slot :name="slot"></slot>
            </template>
            <template :slot="slot" v-if="slot.endsWith('after')">
              <slot :name="slot"></slot>
            </template>
          </template>

        </DetailTable>
    </v-dialog>
  </v-container>
</template>
<script>
import SearchTable from "@/components/SearchTable";
import DataRestTable from "@/components/DataRestTable";
import DetailTable from "@/components/DetailTable";
import {mapGetters} from "vuex";

export default {
  components: {DetailTable, SearchTable, DataRestTable},
  props: {
    title: String,
    resource: String,
    searchSchema: Object,
    searchSchemaName: String,
    resultHeaders: Array,
    schemaName: String,
    disableServerPaging: {
      type: Boolean,
      default: false
    },
    searchParamsProp: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    id: Number
  },
  computed: {
    ...mapGetters([
      'apiDoc',
      'getSchema'
    ]),
    schema() {
      if (this.schemaName) return this.getSchema(this.schemaName)
      return {}
    },
    searchSchemaInner(){
      if(this.searchSchema) return this.searchSchema
      return this.apiDoc ? this.apiDoc.components.schemas[this.searchSchemaName] : {}
    },
    // searchParams: {
    //   get() {
    //     console.log("searchParams", this.searchParamsProp, this.searchParamsInner)
    //     if(this.searchParamsInner) return this.searchParamsInner
    //     return this.searchParamsProp || {}
    //     // return Object.assign({}, this.searchParamsProp, this.searchParamsInner)
    //   },
    //   set(newValue) {
    //     this.searchParamsInner = newValue
    //   }
    // }
  },
  data() {
    return {
      searchTableParams: this.searchParamsProp,
      searchParams: this.searchParamsProp,
      selectedId: undefined,
      model: undefined,
      tableSelected: undefined,
      modalOpen: false
    }
  },
  mounted() {
    if(this.id){
      this.select(this.id)
    }
  },
  methods: {
    search(v) {
      this.searchParams = v
    },
    async fetch() {
      let {data} = await this.$axios.get(`/api/${this.resource}/${this.selectedId}`)
      this.model = data
    },
    async save() {
      try {
        if (this.selectedId) {
          await this.$axios.put(`/api/${this.resource}/${this.selectedId}`, this.model)
        } else {
          await this.$axios.post(`/api/${this.resource}/`, this.model)
        }
        window.bus.$emit('toast', '保存しました')
        await this.$refs.dataTable.fetch()
        this.close()
      } catch (e) {
        if(e.response.status == 403){
          window.bus.$emit('error', '許可されてない操作です')
        }else{
          window.bus.$emit('error', '保存に失敗しました')
        }
      }
    },
    async deleteItem(id){
      await this.$axios.delete(`/api/${this.resource}/${id}`)
      window.bus.$emit('toast', '削除しました')
      await this.$refs.dataTable.fetch()
      this.close()
    },
    add(){
      this.selectedId = undefined
      this.model = {}
      this.modalOpen = true
    },
    async select(id) {
      this.selectedId = id
      await this.fetch()
      this.modalOpen = true
      this.$emit("select", id)
    },
    close(){
      this.selectedId = undefined
      this.modalOpen = false
    }
  },
  // watch: {
  //   selectedId() {
  //     if (this.selectedId) {
  //       this.fetch()
  //     } else {
  //       this.model = undefined
  //     }
  //   }
  // }
}
</script>
