<template>
  <v-container v-if="model">
    <v-row>
      <v-col cols="6">
        <v-autocomplete
            label="コストセンター" v-model="model.costCenterId" :items="costCenters"
            item-text="name"
            item-value="id"
            :append-icon="model.costCenterId && 'mdi-close'"
            @click:append="model.poId = model.costCenterId = null"
            dense
            :disabled="disabled"
            @change="onChangeCostCenter"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
            label="PO-no" v-model="model.poId" :items="pos"
            item-text="no"
            item-value="id"
            dense
            :rules="[rules.required]"
            class="required"
            :disabled="disabled"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="コストセンター管理部署" v-model="costcenterUserDepartmentName" disabled
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="コストセンター担当者" v-model="costcenterUserName" disabled
            dense
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            label="イベント" v-model="model.eventId" :items="events"
            item-text="name"
            item-value="id"
            :append-icon="model.eventId && 'mdi-close'"
            @click:append="model.eventId = null"
            dense
            :rules="[myrules.eventId]"
            class="required"
            :disabled="disabled"
        ></v-autocomplete>
      </v-col>

        <FormWrapper v-model="event" :schema="eventSchema" :options="eventOptions"></FormWrapper>


      <!--      <v-col cols="12">-->
      <!--        <v-text-field-->
      <!--            label="イベント名" v-model="event.name"-->
      <!--            :disabled="!!model.eventId"-->
      <!--            dense-->
      <!--            :rules="[myrules.eventName]"-->
      <!--            class="required"-->
      <!--        ></v-text-field>-->
      <!--      </v-col>-->
      <v-col cols="6">
        <v-text-field
            ref="rfaNumber"
            label="RFA番号" v-model="model.rfaNumber"
            dense
            :rules="[myrules.rfaNumber]"
            class="required"
            :disabled="disabled"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-checkbox
            ref="noRfaNumber"
            label="RFA番号なし" v-model="model.noRfaNumber"
            dense
            :rules="[myrules.noRfaNumber]"
            class="required"
            :disabled="disabled"
        ></v-checkbox>
      </v-col>
      <!--      <v-col cols="12">-->
      <!--        <v-text-field-->
      <!--            label="主催者" v-model="event.organizer"-->
      <!--            :disabled="!!model.eventId"-->
      <!--            dense-->
      <!--        ></v-text-field>-->
      <!--      </v-col>-->
      <!--      <v-col cols="6">-->
      <!--        <v-text-field-->
      <!--            label="開催開始日" v-model="event.startDate"-->
      <!--            :disabled="!!model.eventId"-->
      <!--            dense-->
      <!--        ></v-text-field>-->
      <!--      </v-col>-->
      <!--      <v-col cols="6">-->
      <!--        <v-text-field-->
      <!--            label="開催日数" v-model="event.dateCount"-->
      <!--            :disabled="!!model.eventId"-->
      <!--            dense-->
      <!--        ></v-text-field>-->
      <!--      </v-col>-->
      <!--      <v-col cols="6">-->
      <!--        <v-text-field-->
      <!--            label="開催場所(都道府県)" v-model="event.prefecture"-->
      <!--            :disabled="!!model.eventId"-->
      <!--            dense-->
      <!--        ></v-text-field>-->
      <!--      </v-col>-->
      <!--      <v-col cols="6">-->
      <!--        <v-text-field-->
      <!--            label="開催場所(市区町村)" v-model="event.address"-->
      <!--            :disabled="!!model.eventId"-->
      <!--            dense-->
      <!--        ></v-text-field>-->
      <!--      </v-col>-->
    </v-row>
    <v-row>
    </v-row>
  </v-container>
</template>
<script>
import Rules from "@/mixins/Rules";
import {mapGetters} from "vuex";
import _ from "lodash"
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  components: {FormWrapper},
  mixins: [Rules],
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      costCenters: [],
      pos: [],
      events: [],
      po: null,
      myrules: {
        eventId: (v) => {
          return !!v || !!_.get(this.event, "name") || '必須です'
        },
        eventName: (v) => {
          return (!!v || !!this.model.eventId) || '必須です'
        },
        rfaNumber: (v) => {
          return (!!v || this.model.noRfaNumber) || '必須です'
        },
        noRfaNumber: (v) => {
          return (!!v || !!this.model.rfaNumber) || '必須です'
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'getSchema'
    ]),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
        // if (newValue.eventId) {
        //   newValue.event = null
        //   delete newValue.event
        //   this.$emit('input', newValue)
        // } else {
        //   this.$emit('input', newValue)
        // }
      }
    },
    // po() {
    //   return this.pos.find(value => value.id == this.model.poId) || {}
    // },
    poId(){
      return _.get(this.model, "poId")
    },
    costcenter() {
      return this.costCenters.find(value => value.id == this.model.costCenterId) || {}
    },
    costcenterUserName() {
      return _.get(this.costcenter, "user.name")
    },
    costcenterUserDepartmentName() {
      return _.get(this.costcenter, "user.department.name")
    },
    event: {
      get() {
        if (this.model && this.model.eventId) {
          return this.events.find(value => value.id == this.model.eventId) || {}
        }
        return _.get(this.model, "event") || {}
      },
      set(value) {
        this.$set(this.model, "event", value)
      }
    },
    rfaNumber() {
      return _.get(this.model, "rfaNumber")
    },
    noRfaNumber() {
      return _.get(this.model, "noRfaNumber")
    },
    eventSchema() {
      return this.getSchema('EventDtoFromRequest')
    },
    eventOptions() {
      return {
        disableAll: this.disabled || !!this.model.eventId,
        fieldProps: {
          rules: []
        },
      }
    }
  },
  watch: {
    costcenter(newValue, oldValue) {
      if (newValue.id != oldValue.id) {
        this.fetchPos()
      }
      // if (this.costcenter.userId) {
      //   this.$set(this.model, "destinationUserId", this.costcenter.userId)
      // }
    },
    po() {
      if (this.po.id) {
        this.$set(this.model, "costCenterId", this.po.costCenter.id)
      }
    },
    poId(){
      if(this.poId){
        this.fetchPo(this.poId)
      }
    },
    rfaNumber() {
      this.$refs.noRfaNumber.validate();
    },
    noRfaNumber() {
      this.$refs.rfaNumber.validate();
    },
    // event: {
    //   deep: true,
    //   handler() {
    //     if (!this.model.eventId) {
    //       this.$set(this.model, "event", this.event)
    //     }
    //   }
    // }
  },
  methods: {
    async fetchCostCenters() {
      let {data} = await this.$axios.get("/api/cost_center")
      this.costCenters = data.content
    },
    async fetchPos() {
      let params = Object.assign({
        available: true
      }, this.costcenter ? {
        costCenterId: this.costcenter.id
      } : {})
      let {data} = await this.$axios.get("/api/po", {params})
      this.pos = data.content
      this.addCurrentPo()
    },
    async fetchPo(id){
      let {data} = await this.$axios.get(`/api/po/${id}`)
      this.po = data
      this.addCurrentPo()
    },
    addCurrentPo(){
      this.pos = this.pos || []
      if(this.po && !this.pos.some(v => v.id == this.po.id)){
        this.pos.push(this.po)
      }
    },
    async fetchEvents() {
      let {data} = await this.$axios.get("/api/event")
      this.events = data.content
    },
    onChangeCostCenter(){
      if (this.costcenter.userId) {
        this.$set(this.model, "destinationUserId", this.costcenter.userId)
      }
    }
  },
  mounted() {
    this.fetchCostCenters()
    this.fetchPos()
    this.fetchEvents()
  }

}
</script>