<template>
  <v-container>
    <h1>講演詳細の入力</h1>

    <v-card class="text-center pa-5 detail-table" outlined elevation="1">
      <v-card-text>
        <v-form ref="form">
          <v-row class="text-center">
            <v-col cols="12">
              <FormWrapper v-model="model" schemaName="DocEventSession" :options="{removeAdditionalProperties: true,arrayOperations: ['create','update','delete']}"></FormWrapper>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.stop="save" color="primary" v-if="!readonly">保存</v-btn>
        <v-btn :to="`/doc_event/${eventId}`">戻る</v-btn>
      </v-card-actions>
    </v-card>

  </v-container>
</template>
<script>
// import {mapGetters} from "vuex";
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  components: {FormWrapper},
  props: {
    eventId: String,
    sessionId: String,
  },
  data() {
    return {
      model: null,
      readonly: false
    }
  },
  computed: {
    // ...mapGetters([
    //   'apiDoc',
    //   'getSchema'
    // ]),
    // schema() {
    //   return this.getSchema("DocEvent")
    // }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      let {data} = await this.$axios.get(`/api/doc_event/${this.eventId}/session/${this.sessionId}`)
      this.model = data
    },
    async save(){
      if (!this.$refs.form.validate()) {
        return
      }
      let data = Object.assign({},this.model)
      delete data.event
      await this.$axios.put(`/api/doc_event/${this.eventId}/session/${this.sessionId}`,data)
      window.bus.$emit('toast', '保存しました')
      await this.fetch()
    },
    async close(){
      this.modalOpen = false
    }
  }
}
</script>