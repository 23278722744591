<template>
  <v-container>
    <v-sheet outlined class="pa-4">
      <h3>TKP入力欄</h3>
      <v-row
          class="my-4"
          align="center"
          justify="space-around">
        <v-btn color="primary" @click.stop="stamp('registerDate')" :disabled="disabled">受領</v-btn>
        <v-btn color="error" @click.stop="stamp('defectDate')" :disabled="disabled">申請不備あり</v-btn>
        <template v-if="transport">
          <v-btn color="success" @click.stop="stamp('arrangementCompletedDate')" :disabled="disabled">確定情報入力
          </v-btn>
        </template>
        <template v-else>
          <v-btn color="secondary" @click.stop="stamp('scheduledPaymentDate',5)" :disabled="disabled">予定</v-btn>
          <v-btn color="success" @click.stop="stamp('arrangementCompletedDate')" :disabled="disabled">支払済み</v-btn>
        </template>
      </v-row>

      <FormWrapper v-model="model" v-if="renderSwitch" :schema='transport ? schemaTransport : schema'
                   :options="options"></FormWrapper>

    </v-sheet>
  </v-container>
</template>
<script>
import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days';
import FormWrapper from "@/components/requests/FormWrapper.vue";


let schema = {
  type: "object",
  "properties": {
    "registerDate": {
      "type": "string",
      "title": "TKP内容確認日",
      "x-cols": 6,
      "format": "date"
    },
    "defectDate": {
      "type": "string",
      "title": "申請不備登録日",
      "x-cols": 6,
      "format": "date"
    },
    "scheduledPaymentDate": {
      "type": "string",
      "title": "支払予定日",
      "x-cols": 6,
      "format": "date"
    },
    "arrangementCompletedDate": {
      "type": "string",
      "title": "支払完了日",
      "x-cols": 6,
      "format": "date"
    },
    "transferFeeBeforeTax": {
      "type": "number",
      "title": "振込手数料(税別)"
    },
    "transferFeeTax": {
      "type": "number",
      "title": "振込手数料(消費税)"
    },
    "transferFeeTotalAmount": {
      "type": "number",
      "title": "振込手数料(税込)"
    },
    "noInvoice": {
      "type": "boolean",
      "title": "請求書発行不要"
    },
    "tkpRemarks": {
      "type": "string",
      "title": "メモ",
      "x-display": "textarea",
    },
  }
}

let schemaTransport = {
  type: "object",
  "properties": {
    "registerDate": {
      "type": "string",
      "title": "TKP内容確認日",
      "x-cols": 6,
      "format": "date"
    },
    "defectDate": {
      "type": "string",
      "title": "申請不備登録日",
      "x-cols": 6,
      "format": "date"
    },
    "arrangementCompletedDate": {
      "type": "string",
      "title": "確定情報入力日",
      "x-cols": 6,
      "format": "date"
    },
    "tkpRemarks": {
      "type": "string",
      "title": "メモ",
      "x-display": "textarea",
    },
  }
}

export default {
  components: {FormWrapper},
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    },
    transport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      renderSwitch: true,
      registerDate: null,
      defectDate: null,
      scheduledPaymentDate: null,
      arrangementCompleteDate: null,
      holidays: [],
      schema,
      schemaTransport
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    options() {
      return {disableAll: this.disabled, removeAdditionalProperties: false}
    }
  },
  watch: {
    model: {
      deep: true,
      handler(v) {

        this.$emit("input", v)
      }
    }
  },
  methods: {
    stamp(t, days) {
      days = days || 0
      this.$set(this.model, t, dayjs().businessDaysAdd(days).format('YYYY-MM-DD'))
    },
    async fetchHolidays() {
      let {data} = await this.$axios.get("/api/holiday")
      this.holidays = data.content

      const options = {
        holidays: [...this.holidays.map(v => v.date), "2022-12-09"],
        holidayFormat: 'YYYY-MM-DD',
      };
      dayjs.extend(dayjsBusinessDays, options);
    }
  },
  async mounted() {
    await this.fetchHolidays()
  }

}
</script>