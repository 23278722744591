import Vue from 'vue'
import './plugins/axios'
import './plugins/vjsf'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "@/router";
import store from './store'
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);
global._ = _

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
