<template>
  <CRUDPage
      :resultHeaders="resultHeaders"
      resource="transparency/expense_type"
      title="透明性GL ExpenseType一覧"
      schemaName="TransparencyExpenseType"
  >
    <template v-slot:pageheader>
      <TransparencyMenu></TransparencyMenu>
    </template>
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import TransparencyMenu from "@/pages/master/transparency/TransparencyMenu.vue";

export default {
  components: {TransparencyMenu, CRUDPage},
  data() {
    return {
      resultHeaders: [
        {text: 'Name', value: 'name'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
