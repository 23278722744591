<template>
  <v-container>
    <h1>国内移動交通手配一覧</h1>

    <SearchTable
        schemaName="TransportCostRequestSearchParam"
        @search="search"
    ></SearchTable>

    <RequestDataRestTable
        :headers="headers"
        :searchParams="searchParams"
        resource="request/transport_cost"
    >
      <template v-slot:top>
        <v-btn color="primary" to="/transport_cost_requests/add">新規作成</v-btn>
      </template>
      <template v-slot:item.arrangementCompletedDate="{item}">
        <v-chip x-small color="primary" v-if="item.arrangementCompletedDate">確定済</v-chip>
        <br/>
        {{ item.arrangementCompletedDate }}
      </template>

      <template v-slot:item.actions="{item}">
        <td>
          <v-btn color="primary" :to="`/transport_cost_requests/${item.id}`">詳細</v-btn>
        </td>
      </template>
    </RequestDataRestTable>


  </v-container>
</template>
<script>
import SearchTable from "@/components/SearchTable";
import lodash from "lodash"
import RequestDataRestTable from "@/pages/requests/common/RequestDataRestTable.vue";

export default {
  components: {SearchTable, RequestDataRestTable},
  data() {
    return {
      debug: null,
      lodash,
      searchParams:{},
      headers: [
        {text: '申請No', value: 'id'},
        {text: '担当者', value: 'user.lastName'},
        {text: 'イベント名\n支払内容依頼', value: 'event.name'},
        {text: '開催開始日', value: 'event.startDate'},
        {text: '支払先', value: 'kol.name'},
        {text: '交通', value: 'transport', sortable: false},
        {text: '宿泊', value: 'hotel', sortable: false},
        {text: 'タクチケ', value: 'taxi', sortable: false},
        {text: '申請状況\n申請日', value: 'statuses.inputDate'},
        {text: '内容確認状況\n内容確認日', value: 'statuses.registerDate'},
        {text: '確定日', value: 'statuses.arrangementCompletedDate'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  },
  methods: {
    search(v){
      this.searchParams = v
    }
  }
}

</script>
