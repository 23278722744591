<template>
  <v-container>
    <h1>印刷用資料</h1>
    <table v-if="event">
      <tr>
        <th>イベント名</th>
        <td>{{ event.eventName }}</td>
      </tr>
      <tr>
        <th>講演内容</th>
        <td>{{ guest.session.title }}</td>
      </tr>
      <tr>
        <th>用務内容</th>
        <td>{{ guest.requestedContent }}</td>
      </tr>
      <tr>
        <th>依頼者</th>
        <td>{{ guest.organization }} {{ guest.department }} {{ guest.position }} {{ guest.name }}</td>
      </tr>
    </table>

    <table>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th>印刷日</th>
      </tr>
      <tr v-for="i in guest.documents" :key="i.id">
        <td><v-btn :href="`/api/doc_event/guest/${guest.id}/${i.type.key}`" target="_blank" color="primary" small outlined rounded>印刷する</v-btn></td>
        <td><v-btn color="primary" small outlined rounded v-if="i.type.clazz" @click.stop="open(i)">印刷情報追加</v-btn></td>
        <td>{{i.type.label}}</td>
        <td>{{i.lastPrintedAt}} {{i.userName}}</td>
      </tr>
    </table>

    <v-dialog
        v-model="modalOpen"
        persistent
        max-width="800px"
    >
      <DetailTable :schema="additionalSchema" v-model="additionalModel"
                   @save="save" @close="close"
      ></DetailTable>
    </v-dialog>

  </v-container>
</template>

<script>
import _ from "lodash"
import {mapGetters} from "vuex";
import DetailTable from "@/components/DetailTable.vue";

export default {
  components:{DetailTable},
  props: {
    eventId: String,
    sessionId: String,
    guestId: String,
    id: String,
  },
  data() {
    return {
      // event: null,
      session: {},
      guest: {},
      documents:[],
      modalOpen: false,
      additionalSchema: null,
      additionalModel:null,
      additionalDoc: null
    }
  },
  computed: {
    ...mapGetters([
      'apiDoc',
      'getSchema'
    ]),
    schema() {
      return this.getSchema("DocEventSession")
    },
    event(){
      return _.get(this.guest, "session.event")
    }
  },
  created() {
    // this.fetchEvent()
    // this.fetchSession()
    this.fetchGuest()
  },
  methods: {
    async fetchEvent() {
      let {data} = await this.$axios.get(`/api/doc_event/${this.eventId}`)
      this.event = data
    },
    async fetchSession() {
      let {data} = await this.$axios.get(`/api/doc_event/${this.eventId}/session/${this.sessionId}`)
      this.session = data
    },
    async fetchGuest() {
      let {data} = await this.$axios.get(`/api/doc_event/${this.eventId}/session/${this.sessionId}/guest/${this.guestId}`)
      this.guest = data
    },
    async save(data) {
      await this.$axios.post(`/api/doc_event/${this.eventId}/session/${this.sessionId}/guest/${this.guestId}/${this.additionalDoc.type.key}`, data)
      let orig = this.guest.documents.find(value => value.type.key == this.additionalDoc.type.key)
      orig.data = data
      window.bus.$emit('toast', '保存しました')
    },
    async close() {
      this.modalOpen = false
    },
    open(doc){
      this.additionalDoc = doc
      this.additionalSchema = this.getSchema(doc.type.clazz)
      this.additionalModel = doc.data
      this.modalOpen = true;
    }
  }
}
</script>
<style scoped>
h3 {
  /*color: blue;*/
}

table {
  width: 100%;
  text-align: left;
  margin: 20px;
  padding: 20px;
}

table td {
  /*padding: 20px;*/
}

.session {
  /*border: solid 1px lightblue;*/
  margin: 20px;
  padding: 20px;
}
.guests{
  line-height: 50px;
}
</style>