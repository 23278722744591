<template>
    <v-container v-if="model">
        <v-row>
            <v-col>
                <v-autocomplete
                        label="支払依頼内容" v-model="model.requestContents" :items="types"
                        item-text="label"
                        item-value="label"
                        dense
                        :rules="[rules.required]"
                        class="required"
                        :disabled="disabled"
                        @change="onChange"
                ></v-autocomplete>
                <v-text-field v-model="model.requestContentsOther" v-if="type.hasOther" label="その他"
                              :disabled="disabled">
                </v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Rules from "@/mixins/Rules";

export default {
    mixins: [Rules],
    props: {
        value: {type: Object},
        url: {
            type: String,
            "default": "/api/request/company/types"
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            types: [],
        }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        },
        type() {
            return this.types.find(value => value.label == this.model.requestContents) || {}
        }
    },
    watch: {},
    methods: {
        async fetchTypes() {
            let {data} = await this.$axios.get(this.url)
            this.types = data
        },
        onChange(v) {
            window.bus.$emit('onChangeRequestContents', v)
        }
    },
    mounted() {
        this.fetchTypes()
    }

}
</script>