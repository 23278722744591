<template>
  <v-container v-if="model">
    <v-row>
      <v-col cols="6">
        <v-autocomplete
            label="担当者" v-model="model.userId" :items="users"
            item-text="name"
            item-value="id"
            dense
            :rules="[rules.required]"
            class="required"
            :disabled="!isAdmin"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
            label="担当者 部署" v-model="departmentId" :items="departments"
            item-text="name"
            item-value="id"
            :append-icon="departmentId && 'mdi-close'"
            @click:append="model.userId = departmentId = null"
            dense
            :disabled="!isAdmin"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="担当者TEL" v-model="user.tel" disabled
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="担当者Email" v-model="user.email" disabled
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-container>
</template>
<script>
import Rules from "@/mixins/Rules";
import _ from "lodash"
import {mapGetters} from "vuex";

export default {
  mixins: [Rules],
  props: {
    value: {type: Object}
  },
  data() {
    return {
      users: [],
      departments: [],
      department: null,
      departmentId: null
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin'
    ]),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    user() {
      return this.users.find(value => value.id == this.model.userId) || {}
    },
    userId() {
      return _.get(this.model, "userId");
    }
  },
  watch: {
    user() {
      if (this.userId) {
        this.departmentId = this.user.departmentId
        this.$set(this.model, "userName", this.user.name)
      }
    },
    departmentId() {
      this.fetchUsers()
    }
  },
  methods: {
    async fetchUsers() {
      let opt = Object.assign({
            size: 2000
          },
          this.departmentId ? {departmentId: this.departmentId} : {}
      )
      let {data} = await this.$axios.get("/api/user", {
        params: opt
      })
      this.users = data.content
      if (this.user) {
        this.departmentId = this.user.departmentId
        this.$forceUpdate()
      }
    },
    async fetchDepartments() {
      let {data} = await this.$axios.get("/api/department")
      this.departments = data.content
    }
  },
  mounted() {
    this.fetchUsers()
    this.fetchDepartments()
  }

}
</script>