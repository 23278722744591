<template>
  <RequestsDetail resource="transport_cost" listPage="transport_cost_requests" v-model="model" :id="id">
    <template v-slot:default="{isNew, isAdmin}">
      <h1>国内移動交通手配申請</h1>

      <TransportCostRequestsForm v-model="model" :disabled="!isNew && !isAdmin" :detail="!isNew"></TransportCostRequestsForm>

      <TKPExclusiveForm v-model="model" :disabled="!isNew &&!isAdmin" v-if="!isNew" transport></TKPExclusiveForm>


      <TransportInvoices v-model="model" :disabled="!isNew &&!isAdmin" v-if="!isNew"></TransportInvoices>
    </template>
  </RequestsDetail>
</template>
<script>
import RequestsDetail from "@/pages/requests/common/RequestsDetail.vue";
import TKPExclusiveForm from "@/components/requests/TKPExclusiveForm.vue";
import TransportCostRequestsForm from "@/pages/requests/transport_cost/TransportCostRequestsForm.vue";
import TransportInvoices from "@/pages/requests/common/TransportInvoices.vue";

export default {
  props: {
    id: String,
  },
  data() {
    return {
      model: {}
    }
  },
  components: {
    TransportInvoices,
    TransportCostRequestsForm,
    TKPExclusiveForm,
    RequestsDetail,
  },
}
</script>
