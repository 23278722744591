<template>
  <v-container>
    <h1>講演内容詳細</h1>

    <table v-if="event">
      <tr>
        <th>イベント名</th>
        <td>{{ event.eventName }}</td>
        <td>
          <v-btn :to="`/doc_event/${eventId}/edit`" color="primary" outlined rounded width="150">詳細</v-btn>
        </td>
      </tr>
      <tr>
        <th>開催日</th>
        <td>{{ event.eventDate }}</td>
      </tr>
      <tr>
        <th>開催時間</th>
        <td>開始時間{{ event.startTime }} 終了時間{{ event.endTime }}</td>
      </tr>
    </table>

    <h3 style="border-bottom: solid 1px blue; margin:20px">講演内容の詳細を登録してください</h3>

    <v-card v-for="(s, index) in sessions" :key="s.id" class="session">
<!--      <h3>{{ index + 1 }}枠目-->
<!--        <v-btn rounded color="primary" :to="`/doc_event/${eventId}/session/${s.id}`" width="150" class="ml-6">編集</v-btn>-->
<!--      </h3>-->

      <table class="ma-0 pa-0 session-detail">
        <tr><th>{{ index + 1 }}枠目</th><td><v-btn rounded color="primary" :to="`/doc_event/${eventId}/session/${s.id}`" width="150" class="ml-6">編集</v-btn></td></tr>
        <tr><th>講演内容</th></tr>
        <tr><td>{{ s.title }}</td></tr>
        <tr><th>依頼者</th></tr>
        <tr v-for="guest in s.guests" :key="guest.id">
          <td>{{ guest.name }}</td>
          <td><v-btn :to="`/doc_event/${eventId}/session/${s.id}/guest/${guest.id}`" color="primary" rounded outlined width="150" class="ml-6">各種印刷</v-btn></td>
        </tr>
      </table>

<!--      <h4>講演内容</h4>-->
<!--      <p>{{ s.title }}</p>-->
<!--      <h4>依頼者</h4>-->
<!--      <div v-for="guest in s.guests" :key="guest.id" class="guests">-->
<!--        {{ guest.name }}-->
<!--        <v-btn :to="`/doc_event/${eventId}/session/${s.id}/guest/${guest.id}`" color="primary" rounded outlined width="150" class="ml-6">各種印刷</v-btn>-->
<!--      </div>-->
    </v-card>
  </v-container>
</template>
<script>

export default {
  props: {
    eventId: String
  },
  data() {
    return {
      event: null,
      sessions: []
    }
  },
  created() {
    this.fetch()
    this.fetchSession()
  },
  methods: {
    async fetch() {
      let {data} = await this.$axios.get(`/api/doc_event/${this.eventId}`)
      this.event = data
    },
    async fetchSession() {
      let {data} = await this.$axios.get(`/api/doc_event/${this.eventId}/session`)
      this.sessions = data.content
    }
  }
}
</script>
<style scoped>
h3 {
  /*color: blue;*/
}

table {
  width: 100%;
  text-align: left;
  margin: 20px;
  padding: 20px;
}

table td {
  /*padding: 20px;*/
}

.session {
  /*border: solid 1px lightblue;*/
  margin: 20px;
  padding: 20px;
}
.guests{
  line-height: 50px;
}
.session-detail th{
  min-width: 100px;
}
.session-detail td{
  padding: 5px 0;
}
</style>