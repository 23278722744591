<template>
  <v-container v-if="model">
    <v-row>
      <v-col>
        <v-autocomplete
            label="お客様情報" v-model="model.kolId" :items="kols"
            item-text="name"
            item-value="id"
            dense
            :disabled="disabled"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <FormWrapper v-model="kol"
                   :schemaName="hasPhilipsAdmin ? 'Kol' : 'KolLimited'" disabled/>
    </v-row>
  </v-container>
</template>

<script>
import Rules from "@/mixins/Rules";
// import _ from "lodash"
import {mapGetters} from "vuex";
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  components: {FormWrapper},
  mixins: [Rules],
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      kols: [],
      kol: null
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin', 'hasPhilipsAdmin'
    ]),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    // kol() {
    //   return this.kols.find(value => value.id == this.model.kolId) || {}
    // },
    kolId(){
      return this.model.kolId
    },
    options() {
      let ret = {
        fieldProps: {
          rules: []
        }
      }
      return ret;
    }
  },
  watch: {
    kol(v){
      this.$set(this.model, "kol", v)
    },
    kolId(){
      this.fetchKol()
    }
  },
  methods: {
    async fetchKols() {
      let {data} = await this.$axios.get("/api/kol")
      this.kols = data.content
    },
    async fetchKol() {
      if(!this.kolId) {
        this.kol = {}
        return
      }
      let {data} = await this.$axios.get(`/api/kol/${this.kolId}`)
      this.kol = data
    }
  },
  mounted() {
    this.fetchKols()
    this.fetchKol()
  }

}
</script>