<template>
  <v-container class="pa-5">
    <h1>HOME</h1>
    <section>
      <!--      <h2>お知らせ一覧</h2>-->
      <v-card max-width="800" shaped elevation="2">
        <!--        <v-card-title>お知らせ一覧</v-card-title>-->
        <v-toolbar
            color="grey"
            dark
            dense
        >
          <v-icon class="mr-3">mdi-information</v-icon>
          <v-toolbar-title>お知らせ一覧</v-toolbar-title>
        </v-toolbar>

        <v-list shaped>
          <v-list-item-group color="primary">
            <v-list-item v-for="(item, i) in infos" :key="i" two-line :to="`/info/${item.id}`">
<!--              <v-list-item-icon v-if="isNew(item)">-->
<!--                <v-icon>mdi-new-box</v-icon>-->
<!--              </v-list-item-icon>-->
              <v-list-item-content>
                <v-list-item-title><span v-if="isNew(item)" class="red--text caption">NEW</span> {{item.title}}</v-list-item-title>
                <v-list-item-subtitle>{{ item.sendDateTime | dateFormat }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain to="/info">もっとみる</v-btn>
        </v-card-actions>
      </v-card>
    </section>

    <section>
      <h2>各種依頼申請</h2>
      <v-row dense>
        <LinkButton :item="item" v-for="(item, index) in links.add.items" :key="index"></LinkButton>
      </v-row>
    </section>

    <section>
      <h2>申請一覧</h2>
      <v-row dense>
        <LinkButton :item="item" v-for="(item, index) in links.list.items" :key="index"></LinkButton>

      </v-row>
    </section>

    <section>
      <h2>連携システム</h2>
      <v-row dense>
        <LinkButton :item="{
                  title: '会場予約システム',
                  href: 'https://www.secure-cloud.jp/sf/business/1668128117TZdVnYoG',
                  target: '_blank',
                  icon: 'mdi-domain',
                  color: 'teal'
        }"></LinkButton>
        <LinkButton :item="{
                  title: 'イベント書類作成システム',
                  to: '/doc_event',
                  icon: 'mdi-domain',
                  color: 'secondary'
        }"></LinkButton>
      </v-row>
    </section>

<!--    <section>-->
<!--      <DataRestTable-->
<!--          :headers="headers"-->
<!--          resource="request"-->
<!--          :searchParams="searchParams"-->
<!--          ref="dataTable"-->
<!--      >-->
<!--        <template v-slot:item.actions="{item}">-->
<!--          <td>-->
<!--            <v-btn color="primary" @click="select(item.id)" v-if="item">詳細</v-btn>-->
<!--          </td>-->
<!--        </template>-->
<!--      </DataRestTable>-->
<!--    </section>-->


<!--    <v-btn href="http://localhost:8000/swagger-ui/index.html">doc</v-btn>-->
<!--    <v-btn href="http://localhost:8000/v3/api-docs/">doc</v-btn>-->
  </v-container>

</template>
<script>
import LinkButton from "@/components/LinkButton";
import {mapGetters} from "vuex";
import dayjs from "dayjs";


export default {
  components: { LinkButton},
  data() {
    return {
      // me: null,
      selectedItem: undefined,
      infos: [],
      headers: [
        {text: 'No', value: 'id'},
        {text: '申請No', value: 'no'},
        {text: '担当者', value: 'name'},
        {text: 'イベント名\n支払内容依頼', value: 'name'},
        {text: '開催開始日', value: 'name'},
        {text: '支払先', value: 'name'},
        {text: '申請状況\n申請日', value: 'inputDate'},
        {text: '内容確認状況\n内容確認日', value: 'registerDate'},
        {text: '支払予定日', value: 'scheduledPaymentDate'},
        {text: '支払日', value: 'arrangementCompletedDate'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
      searchParams: {},
      links: {
        add:
            {
              title: '支払い申請', icon: 'mdi-pencil',
              items: [
                {
                  title: "法人宛支払依頼申請",
                  to: "/company_requests/add",
                  icon: 'mdi-domain',
                  color: "primary"
                },
                {
                  title: "お客様支払依頼申請",
                  to: "/kol_requests/add",
                  icon: 'mdi-account-tie',
                  color: "secondary"
                },
                {
                  title: "交通費立替精算申請",
                  to: "/travel_cost_requests/add",
                  icon: 'mdi-train-car',
                  color: "accent"
                },
                {
                  title: "国内移動交通手配申請",
                  to: "/transport_cost_requests/add",
                  icon: 'mdi-train',
                  color: "success"
                },
                {
                  title: "海外移動交通手配申請",
                  to: "/transport_cost_abroad_requests/add",
                  icon: 'mdi-airplane-takeoff',
                  color: "info"
                },
              ]
            },
        list: {
          title: '申請一覧', icon: 'mdi-format-list-bulleted-square',
          items: [
            {
              title: "法人宛支払依頼一覧",
              to: "/company_requests",
              icon: 'mdi-domain',
              color: "primary"
            },
            {
              title: "お客様支払依頼一覧",
              to: "/kol_requests",
              icon: 'mdi-account-tie',
              color: "secondary"
            },
            {
              title: "交通費立替精算一覧",
              to: "/travel_cost_requests",
              icon: 'mdi-train-car',
              color: "accent"
            },
            {
              title: "国内移動交通手配一覧",
              to: "/transport_cost_requests",
              icon: 'mdi-train',
              color: "success"
            },
            {
              title: "海外移動交通手配一覧",
              to: "/transport_cost_abroad_requests",
              icon: 'mdi-airplane-takeoff',
              color: "info"
            },
          ]
        }
      },
    }
  },
  computed: {
    ...mapGetters([
      'loggedIn', 'me', 'authorities'
    ]),
  },
  mounted() {
    this.fetchInfos()
    // this.fetchMe()
  },
  methods: {
    async fetchInfos() {
      let {data} = await this.$axios.get("/api/info?size=5")
      this.infos = data.content
    },
    // async fetchMe() {
    //   let {data} = await this.$axios.get("/api/me")
    //   this.me = data
    // },
    select() {

    },
    isNew(item){
      return dayjs().subtract(1,'month').isBefore(item.sendDateTime)
    }
  },
  filters: {
    dateFormat(v) {
      if (!v) return ''
      console.log(v)
      return new Date(v).toLocaleDateString()
    }
  }
}
</script>
<style scoped lang="scss">
section {
  margin: 30px auto
}

section h2 {
  margin: 10px auto;
}

/*.links .col {*/
/*  !*max-width: 120px;*!*/
/*  flex: 1 1 0;*/
/*  width: 0;*/
/*}*/

/*.links div {*/
/*  !*font-size: 0.8rem;*!*/
/*  white-space: break-spaces;*/
/*}*/

/*v-list-item {*/
/*  flex-wrap: wrap*/
/*}*/
</style>
