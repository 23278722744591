<template>
  <v-container>
    <v-row v-if="items.length > 0">
      <v-col v-for="item in items" :key="item">
        <v-checkbox
            v-model="documentTypes"
            :label="item"
            :value="item"
            :disabled="disabled"
            :rules="[rules.documentTypes]"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-sheet>
    <span v-for="item in internalValue.files" :key="item.key" class="mr-1">
            <v-chip close
                    :href="item.url"
                    @click:close="removeDocument(item)">
              {{ item.fileName }}
            </v-chip>
          </span>
      <v-file-input show-size
                    label="ファイルアップロード"
                    multiple
                    :disabled="disabled"
                    :rules="[rules.files]"
                    @change="upload"></v-file-input>
    </v-sheet>


    <v-textarea label="添付書類備考･追記" filled class="mt-3" v-model="internalValue.documentRemarks"
                :disabled="disabled">
    </v-textarea>
  </v-container>
</template>
<script>
import _ from "lodash"

export default {
  data() {
    return {
      items: this.itemsProp || ["請求書", "領収書", "趣意書", "社内稟議書", "その他"],
      lazyValue: this.value || {},
      rules: {
        documentTypes: () => !this.required || this.documentTypes.length > 0 || "必須項目です",
        files: () => !this.required || (this.internalValue.files && this.internalValue.files.length > 0) || "必須項目です",
      }
    }
  },
  props: {
    value: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemsProp:{
      type: Array
    },
    required:{
      type: Boolean,
      default: true
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyValue || {}
      },
      set(newValue) {
        this.lazyValue = newValue
        this.$emit('input', newValue)
      }
    },
    documentTypes: {
      get() {
        return _.get(this.internalValue, "documentTypes") || []
      },
      set(newValue) {
        this.$set(this.internalValue, "documentTypes", newValue)
      }
    },
    documentRemarks() {
      return this.internalValue.documentRemarks
    }
  },
  watch: {
    value(v) {
      this.lazyValue = v
    },
    internalValue:{
      deep: true,
      handler(v){
        this.$emit("input", v)
      }
    },
  },
  methods: {
    async upload(file) {
      if (!file) return
      if (Array.isArray(file)) {
        this.loading = true
        try {
          for (let i in file) {
            await this.upload(file[i])
          }
        } catch (e) {
          console.error(e)
        }
        this.loading = false
        return
      }
      if (file.size > 20971520) {
        alert('ファイルサイズは20MB以下でアップロードしてください')
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await this.$axios.post('/api/file/upload', params)
      let documents = _.get(this.internalValue, "files") || []
      documents.push(data)
      this.$set(this.internalValue, "files", documents)
      // this.$forceUpdate()
    },
    removeDocument(item) {
      console.log("removeDocument")
      this.internalValue.files = this.internalValue.files.filter(v => v.key != item.key)
    },
  }
}
</script>