<template>
  <CRUDPage
      :searchSchema="searchSchema"
      :resultHeaders="resultHeaders"
      resource="holiday"
      title="祝日マスタ一覧"
      schemaName="Holiday"
  >
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";

export default {
  components: {CRUDPage},
  data() {
    return {
      searchSchema: {
        type: "object",
        "properties": {
          "year": {
            "type": "number",
            "title": "年"
          }
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '日付', value: 'date'},
        {text: '名称', value: 'name'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
