<template>
  <v-container>
    <v-card v-if="message">
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-btn to="/">戻る</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="token">
      <v-card-title>パスワードリセット</v-card-title>
      <v-card-text>
        <v-form ref="form1">
          <v-text-field v-model="passwordCreate" label="パスワード" :rules="[rules.password]"></v-text-field>
          <v-text-field v-model="passwordConfirm" label="確認" :rules="[rules.passwordConfirm]"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="resetPassword" color="primary">パスワードリセット</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>パスワードリセット</v-card-title>
      <v-card-text>
        <p>
          ご登録いただいているログインIDを入力の上、送信ボタンを押してください。<br/>
          新しいパスワードが記載されたメールを送信します。
        </p>
        <v-form ref="form">
          <v-text-field v-model="loginId" label="ログインID" :rules="[rules.required]"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click.stop="submit" color="primary">パスワードリセット</v-btn>
        <v-btn to="/">戻る</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import Rules from "@/mixins/Rules.vue";

export default {
  mixins: [Rules],
  props: {
    token: String
  },
  data() {
    return {
      loginId: null,
      message: null,
      passwordCreate: null,
      passwordConfirm: null
    }
  },
  created() {
    if (this.token) {
      this.verifyToken()
    }
  },
  methods: {
    async verifyToken() {
      try {
        await this.$axios.post(`/auth/password_reset/verify/${this.token}`)
      } catch (e) {
        this.message = "無効なトークンです"
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        window.bus.$emit('error', '入力内容に不備があります')
        return
      }
      try {
        await this.$axios.post("/auth/password_reset", {
          loginId: this.loginId
        })
        window.bus.$emit('toast', 'パスワードリセットメールを送信しました')
        this.message = "パスワードリセットメールを送信しました"
      } catch (e) {
        switch (e.response.status) {
          case 404:
            window.bus.$emit('error', 'ユーザーが見つかりません')
            return
        }
        window.bus.$emit('error', 'ユーザーが見つかりません')
      }
    },
    async resetPassword() {
      if (!this.$refs.form1.validate()) {
        window.bus.$emit('error', '入力内容に不備があります')
        return
      }
      try {
        await this.$axios.post("/auth/password_reset/confirm", {
          token: this.token,
          password: this.passwordCreate
        })
        window.bus.$emit('toast', 'パスワードをリセットしました')
        this.message = "パスワードをリセットしました"
      } catch (e) {
        window.bus.$emit('error', '保存に失敗しました')
      }
    }
  },
  computed: {
    options() {
      return {
        fieldColProps: {
          rules: this.rules
        }
      }
    }
  }
}
</script>