<template>
  <v-sheet class="text-center pa-5 my-5 search-table" outlined elevation="1"
           @keydown.enter="search">
    <v-form ref="form" v-model="valid" @submit.prevent="search">
      <VJsf :schema="schemaInner" v-model="model" :options="options"></VJsf>
      <v-row justify="center">
        <v-btn class="align-center" color="primary" @click.stop="search" width="100">検索</v-btn>
        <v-btn class="align-center ml-2" @click.stop="clear" width="100">クリア</v-btn>
      </v-row>
    </v-form>
  </v-sheet>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  props: {
    schema: Object,
    schemaName: String,
    value: Object
  },
  data() {
    return {
      valid: false,
      options: {},
      modelInner: null,
      modelChanged: false
    }
  },
  methods: {
    search() {
      if(!this.$refs.form.validate()) return
      this.$emit("search", this.model)
    },
    clear() {
      this.model = {}
    }
  },
  computed:{
    ...mapGetters([
      'getSchema'
    ]),
    model:{
      get() {
        return this.value || this.modelInner || {}
      },
      set(newValue) {
        console.log("search model newvalue", newValue)
        this.modelInner = newValue
        this.$emit('input', newValue)
      }
    },
    schemaInner() {
      if (this.schema) return this.schema
      if (this.schemaName) return this.getSchema(this.schemaName)
      return {}
    },
  },
  watch: {
    model(v) {
      console.log(v)
      this.modelChanged = true
    }
  }
}
</script>
<style>
.search-table .v-input {
  padding: 0 5px
}
</style>
