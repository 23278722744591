<template>
  <div class="d-inline-block">
<!--    {{item}}-->
    <v-btn small color="accent" disabled v-if="status == 'APPROVED'">承認済み</v-btn>
<!--    <template v-else-if="status == 'REJECTED'">-->
<!--      <v-btn small color="accent" disabled @click.stop="approve" >承認する</v-btn>-->
<!--      <v-btn small color="danger" disabled @click.stop="reject" >差し戻す</v-btn>-->
<!--    </template>-->
    <template v-else-if="status == 'REQUESTED'">
      <v-btn small color="accent" @click.stop="approve" >承認する</v-btn>
      <v-btn small color="error" @click.stop="reject" >差し戻す</v-btn>
    </template>
    <v-btn small color="secondary" @click.stop="requestApproval" v-else-if="isAdmin">承認依頼する</v-btn>
  </div>

</template>
<script>
import _ from "lodash"
import {mapGetters} from "vuex";

export default {
  props: {
    item: Object
  },
  data() {
    return {
      data: null
    }
  },
  computed:{
    ...mapGetters([
      'isAdmin'
    ]),
    status(){
      return _.get(this.data, "status")
    }
  },
  watch:{
    item(){
      this.get()
    }
  },
  created() {
    this.get()
  },
  methods: {
    async get() {
      let {data} = await this.$axios.get(`/api/invoice/status/${this.item.poId}/${this.item.userId}/${this.item.yearMonth}`)
      this.data = data
    },
    async requestApproval() {
      try {
        let {data} = await this.$axios.post(`/api/invoice/status/request/${this.item.poId}/${this.item.userId}/${this.item.yearMonth}`)
        this.data = data
        window.bus.$emit("toast", "承認依頼を出しました")
      } catch (e) {
        window.bus.$emit("error", "エラーが発生しました" + e)
      }
    },
    async reject() {
      if (!confirm("本当に差し戻しますか？")) return
      try {
        let {data} = await this.$axios.post(`/api/invoice/status/reject/${this.item.poId}/${this.item.userId}/${this.item.yearMonth}`)
        this.data = data
        window.bus.$emit("toast", "差し戻しました")
      } catch (e) {
        window.bus.$emit("error", "エラーが発生しました" + e)
      }
    },
    async approve() {
      if (!confirm("本当に承認しますか？")) return
      try {
        let {data} = await this.$axios.post(`/api/invoice/status/approve/${this.item.poId}/${this.item.userId}/${this.item.yearMonth}`)
        this.data = data
        window.bus.$emit("toast", "承認しました")
      } catch (e) {
        window.bus.$emit("error", "エラーが発生しました" + e)
      }
    }
  }
}

</script>