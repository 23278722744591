<template>
  <v-app>
    <v-app-bar
        dense clipped-left app permanent
        color="primary" dark
    >
      <v-app-bar-nav-icon @click.stop="toggleDrawer" v-if="loggedIn"></v-app-bar-nav-icon>
      <v-img src="@/assets/logo2.png" contain max-height="36" max-width="48"></v-img>
      <div class="d-flex align-center">
        支払い代行システム
      </div>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-menu offset-y v-if="loggedIn">
          <template v-slot:activator="{on, attrs}">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
              メニュー
<!--             メニュー {{ me.organization }} {{ me.lastName }} {{ me.firstName }}-->
<!--              {{ me.principal.username }}-->
            </v-btn>
          </template>
          <v-list style="text-align: left">
            <v-list-item link>
              <v-list-item-title
                  @click="logOut">
                ログアウト
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>

    </v-app-bar>

    <v-main>
      <v-navigation-drawer
          v-model="drawer"
          clipped app
          width="280"
          v-if="loggedIn"
      >
        <v-card class="ma-3">
          <v-card-text>
            権限: {{ me.authLabel }}<br/>
            {{ me.lastName }}{{ me.firstName }}様<br/>
            Email: {{ me.email }}
          </v-card-text>
        </v-card>
        <v-list dense nav class="side-menu">
          <template v-for="item in items"
                    v-show="!item.auth || item.auth(authorities)">
            <v-list-group v-if="item.items"
                          :key="item.title"
                          :prepend-icon="item.icon || 'mdi-label'"
                          no-action
            >
              <template v-slot:activator>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>
              <v-list-item link exact-path
                           v-for="item in item.items"
                           :key="item.title"
                           :to="item.to">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>{{ item.icon || 'mdi-view-dashboard' }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else
                         link
                         exact-path
                         :key="item.title"
                         :to="item.to"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon || 'mdi-view-dashboard' }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

      </v-navigation-drawer>

      <router-view
          :key="$route.fullPath"
          @toast="toast"
          @error="error"></router-view>
    </v-main>
    <v-snackbar v-model="showToast" top :color="toastColor">
      {{ toastText }}
    </v-snackbar>
  </v-app>
</template>

<script>

// const hasRole = (arr) => {
//   arr = arr || []
//   return (roles) => (roles || []).some(r => arr.includes(r))
// }

import Vue from "vue";
import {mapGetters} from "vuex";

let bus = window.bus = new Vue()

export default {
  name: 'App',

  async created() {
    bus.$on('toast', this.toast)
    bus.$on('error', this.error)
    this.$on('toast', this.toast)
    this.$on('error', this.error)

    try{
      let p = this.$store.dispatch('getApiDoc')
      let p2 = this.$store.dispatch('getMe')
      await Promise.all([p, p2])
    }catch (e) {
      console.log(e)
    }
  },

  data() {
    return {
      drawer: true,
      showToast: false,
      toastText: null,
      toastColor: 'success',
      roles: null
    }
  },

  computed: {
    ...mapGetters([
      'loggedIn', 'me'
    ]),
    authorities() {
      return this.data && this.data.authorities
    },
    items() {
      return [
        {
          title: 'HOME', icon: 'mdi-home',
          to: {path: '/', params: {eventId: this.eventId}}
        },
        {
          title: '支払い申請', icon: 'mdi-pencil',
          items: [
            {
              title: "法人宛支払依頼申請",
              to: "/company_requests/add",
              icon: 'mdi-domain'
            },
            {
              title: "お客様支払依頼申請",
              to: "/kol_requests/add",
              icon: 'mdi-account-tie'
            },
            {
              title: "交通費立替精算申請",
              to: "/travel_cost_requests/add",
              icon: 'mdi-train-car'
            },
            {
              title: "国内移動交通手配申請",
              to: "/transport_cost_requests/add",
              icon: 'mdi-train'
            },
            {
              title: "海外移動交通手配申請",
              to: "/transport_cost_abroad_requests/add",
              icon: 'mdi-airplane-takeoff'
            },
          ]
        },
        {
          title: '申請一覧', icon: 'mdi-format-list-bulleted-square',
          items: [
            {
              title: "法人宛支払依頼一覧",
              to: "/company_requests",
              icon: 'mdi-domain'
            },
            {
              title: "お客様支払依頼一覧",
              to: "/kol_requests",
              icon: 'mdi-account-tie'
            },
            {
              title: "交通費立替精算一覧",
              to: "/travel_cost_requests",
              icon: 'mdi-train-car'
            },
            {
              title: "国内移動交通手配一覧",
              to: "/transport_cost_requests",
              icon: 'mdi-train'
            },
            {
              title: "海外移動交通手配一覧",
              to: "/transport_cost_abroad_requests",
              icon: 'mdi-airplane-takeoff'
            },
          ]
        },
        {
          title: 'コストセンターマスター一覧',
          to: "/cost_center",
          icon: 'mdi-piggy-bank'
        },
        {
          title: 'PO番号確認・検索',
          to: "/po",
          icon: 'mdi-database'
        },
        {
          title: 'イベント情報検索',
          to: "/event",
          icon: 'mdi-ticket'
        },
        {
          title: 'お客様情報確認',
          to: "/kol",
          icon: 'mdi-chess-knight'
        },
        {
          title: '法人情報確認',
          to: "/company",
          icon: 'mdi-chess-queen'
        },
        {
          title: 'ユーザー管理',
          to: "/user",
          icon: 'mdi-account'
        },
        {
          title: '招聘状',
          to: "/letter",
          icon: 'mdi-printer'
        },
        {
          title: 'イベント書類作成システム',
          to: "/doc_event",
          icon: 'mdi-printer'
        },
        {
          title: '会計管理',
          icon: 'mdi-chart-areaspline-variant'
        },
        {
          title: '請求書発行',
          to: "/invoice",
          icon: 'mdi-receipt'
        },
        {
          title: 'タクシーチケット管理',
          icon: 'mdi-taxi'
        },
        {
          title: 'CSVダウンロード',
            to: "/export",
          icon: 'mdi-download'
        },
        {
          title: 'マスタ管理',
          icon: 'mdi-cog',
          items: [
            {
              title: "部署マスタ管理",
              to: "/department",
              icon: 'mdi-office-building'
            },
            {
              title: "お知らせ配信管理",
              to: "/info_man",
              icon: 'mdi-information'
            },
            {
              title: "祝日マスタ管理",
              to: "/holiday",
              icon: 'mdi-calendar-heart'
            },
            {
              title: "メール送信履歴管理",
              to: "/mail_history",
              icon: 'mdi-email-newsletter'
            },
            {
              title: "メールテンプレートマスタ管理",
              to: "/mail_template",
              icon: 'mdi-email-edit'
            },

            {
              title: "透明性GL",
              icon: 'mdi-cog',
              to: "/transparency/master",
            },
            {
              title: "サービス管理情報",
              icon: 'mdi-cog',
              to: "/service",
            },
          ]
        },
        {
          title: "Q&A",
          icon: 'mdi-chat-question',
        },
        {
          title: "規約PDF",
          icon: 'mdi-file-pdf-box',
          items:[
            {
              title: "タクシーチケット規約_医療従事者向け",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "タクシーチケット規約_社員向け",
              icon: 'mdi-file-pdf-box',
            },
          ]
        },
        {
          title: "利用マニュアル",
          icon: 'mdi-file-pdf-box',
          items:[
            {
              title: "支払代行マニュアル",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "謝礼金支払フローのご案内",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "請求宛名についてご案内",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "請求書発行時の宛名変更依頼",
              icon: 'mdi-file-pdf-box',
            },
          ]
        },
        {
          title: "システム利用マニュアル",
          icon: 'mdi-file-pdf-box',
          items:[
            {
              title: "システム使用マニュアル（ログイン・法人・個人支払）",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "システム使用マニュアル（交通費立替精算）",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "システム使用マニュアル（移動交通手配）",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "システム使用マニュアル（会場予約システム）",
              icon: 'mdi-file-pdf-box',
            },
            {
              title: "システム使用マニュアル（イベント書類作成）",
              icon: 'mdi-file-pdf-box',
            },
          ]
        },
      ]
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    toast (v) {
      this.toastText = v
      this.toastColor = 'success'
      this.showToast = true
    },
    error (v) {
      this.toastText = v
      this.toastColor = 'error'
      this.showToast = true
    },
    async logOut(){
      await this.$store.dispatch('logout')
      await this.$router.push("/login")
    }
  }
};
</script>
<style>
.side-menu .v-list-item__title{
  white-space: pre-line;
}
.required label::after,
.required .v-input--radio-group legend::after{
  content: " *";
  color: red;
}

.required .v-input--radio-group label::after {
  content: "";
}
.vjsf-array-item .v-card{
  box-shadow: none !important;
}
.vjsf-array-item .v-alert--outlined {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>