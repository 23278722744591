<template>
  <CRUDPage
      :searchSchema="searchSchema"
      :resultHeaders="resultHeaders"
      resource="department"
      title="部署マスタ一覧"
      schemaName="DepartmentDto"
  >
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";

export default {
  components: {CRUDPage},
  data() {
    return {
      searchSchema: {
        type: "object",
        "properties": {
          "name": {
            "type": "string",
            "title": "名称",
          }
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '並び順', value: 'sort'},
        {text: '所属', value: 'affiliation'},
        {text: '名称', value: 'name'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
