<template>
  <CRUDPage
      :resultHeaders="resultHeaders"
      resource="doc_event"
      title="イベントリスト"
      schemaName="DocEvent"
  >
    <template v-slot:item.actions="{item}">
      <td>
        <v-btn color="primary" :to="`/doc_event/${item.id}`">詳細</v-btn>
      </td>
    </template>


    <template slot="eventName-before">
      <h3 class="text-left my-5 pl-3" style="font-size: 18px; border-left: solid 5px" >開催情報</h3>
    </template>
    <template slot="venueName-before">
      <h3 class="text-left my-5 pl-3" style="font-size: 18px; border-left: solid 5px" >会場情報</h3>
    </template>
    <template slot="organizerName-before">
      <h3 class="text-left my-5 pl-3" style="font-size: 18px; border-left: solid 5px" >主催・連絡先情報（書類の提出者・事業部責任者、イベントの担当者/連絡先）</h3>
      <h4 class="text-left my-5 pl-3" style="font-size: 18px;" >書類の提出者・事業部責任者</h4>
    </template>
    <template slot="managementCompanyName-before">
      <h4 class="text-left my-5 pl-3" style="font-size: 18px;" >イベントの担当者/連絡先</h4>
    </template>

  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import {mapGetters} from "vuex";

export default {
  components: {CRUDPage},
  computed:{
    ...mapGetters([
      'isAdmin', 'hasPhilipsAdmin'
    ]),
  },
  data() {
    return {
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: 'イベント名', value: 'eventName'},
        {text: '開催形式', value: 'eventForm'},
        {text: '開催内容', value: 'eventType'},
        {text: '開催日', value: 'eventDate'},
        {text: '開催未・済', value: 'eventStatus'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
