<template>
  <v-container>
    <h1>サービス管理詳細</h1>
    <v-sheet outlined class="border-1 pa-2 ma-2">
      <v-form :valid="valid" ref="form">
        <FormWrapper v-model="model" schemaName="ServiceParams"></FormWrapper>
        <v-btn @click.stop="save" color="primary">保存</v-btn>
      </v-form>
    </v-sheet>
  </v-container>

</template>
<script>
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  components: {
    FormWrapper
  },
  data() {
    return {
      valid: false,
      model: null,
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      let {data} = await this.$axios.get("/api/service")
      this.model = data
    },
    async save() {
      if (!this.$refs.form.validate()) {
        window.bus.$emit('error', '入力内容に不備があります')
        return
      }
      try {
        await this.$axios.put("/api/service", this.model)
        window.bus.$emit('toast', '保存しました')
      } catch (e) {
        window.bus.$emit('error', '保存に失敗しました')
      }
    }
  }
}
</script>