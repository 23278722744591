<template>
  <v-container v-if="model">
    <v-row>
      <v-col cols="12">
        <v-autocomplete
            label="所属" v-model="model.destinationAffiliation" :items="affiliations"
            item-text="name"
            item-value="id"
            :append-icon="affiliationId && 'mdi-close'"
            @click:append="affiliationId = null"
            dense
            :disabled="disabled"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
            label="担当者 部署" v-model="departmentId" :items="departments"
            item-text="name"
            item-value="id"
            :append-icon="departmentId && 'mdi-close'"
            @click:append="model.destinationUserId = departmentId = null"
            dense
            :disabled="disabled || !destinationIsPhillips"
            :rules="destinationIsPhillips ? [rules.required]: []"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
            label="担当者" v-model="model.destinationUserId" :items="users"
            item-text="name"
            item-value="id"
            dense
            :disabled="disabled || destinationIsOther"
            :rules="!destinationIsOther ? [rules.required]: []"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field
            label="所属名"
            :disabled="!destinationIsOther"
            dense
            v-model="model.destinationAffiliationName"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            label="部署名"
            :disabled="!destinationIsOther"
            dense
            v-model="model.destinationDepartmentName"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            label="担当者名"
            :disabled="!destinationIsOther"
            dense
            v-model="model.destinationUserName"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="郵便番号" v-model="user.postalCode"
            dense disabled
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="都道府県" v-model="user.prefecture"
            dense disabled
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="住所1" v-model="user.address1"
            dense disabled
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="住所2" v-model="user.address2"
            dense disabled
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="TEL" v-model="user.tel"
            dense disabled
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            label="FAX" v-model="user.fax"
            dense disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            label="Email" v-model="user.email"
            dense disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-container>
</template>
<script>
import _ from "lodash"

import Rules from "@/mixins/Rules.vue";

export default {
  mixins:[Rules],
  props: {
    value: {type: Object},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      affiliations:[
          "株式会社フィリップス・ジャパン", "その他"
      ],
      affiliationId:null,
      users: [],
      departments: [],
      department: null,
      departmentId: null
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    user() {
      return this.users.find(value => value.id == this.model.destinationUserId) || {}
    },
    destinationIsOther(){
      return _.get(this.model, "destinationAffiliation") == "その他"
    },
    destinationIsPhillips(){
      return _.get(this.model, "destinationAffiliation") == "株式会社フィリップス・ジャパン"
    }
  },
  watch: {
    user() {
      if (this.user.id) {
        this.departmentId = this.user.departmentId
        this.$set(this.model, "destinationAffiliation", this.user.affiliation)
      }
    },
    departmentId() {
      this.fetchUsers()
    }
  },
  methods: {
    async fetchUsers() {
      let opt = this.departmentId ? {departmentId: this.departmentId} : {}
      let {data} = await this.$axios.get("/api/user/all", {
        params: opt
      })
      this.users = data
    },
    async fetchDepartments() {
      let {data} = await this.$axios.get("/api/department")
      this.departments = data.content
    }
  },
  mounted() {
    this.fetchUsers()
    this.fetchDepartments()
  }

}
</script>