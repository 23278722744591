<template>
  <CRUDPage
      :searchSchema="searchSchema"
      :resultHeaders="resultHeaders"
      resource="user"
      title="ユーザー一覧"
      :schemaName="hasPhilipsAdmin ? 'PaysysUserBody' : 'PaysysUserBodyLimited'"
  >
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import {mapGetters} from "vuex";

export default {
  components: {CRUDPage},
  data() {
    return {
      searchSchema: {
        type: "object",
        "properties": {
          "name": {
            "type": "string",
            "title": "氏名",
          },
          "departmentId": {
            "type": "string",
            "title": "部署",
            "x-fromUrl": "/api/department",
            'x-itemsProp': 'content',
            'x-itemTitle': 'name',
            'x-itemKey': 'id'
          }
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '所属', value: 'affiliation'},
        {text: '部署', value: 'departmentName'},
        {text: '氏名', value: 'name'},
        {text: '権限', value: 'type'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  },
  computed:{
    ...mapGetters([
      'hasPhilipsAdmin'
    ]),

  }
}
</script>
