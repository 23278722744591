<template>
  <v-container>
    <h1>法人宛支払依頼一覧</h1>

    <SearchTable
        schemaName="CompanyRequestSearchParam"
        @search="search"
    ></SearchTable>

    <RequestDataRestTable
        :headers="headers"
        :searchParams="searchParams"
        resource="request/company"
    >
      <template v-slot:top>
        <v-btn color="primary" to="/company_requests/add">新規作成</v-btn>
      </template>
      <template v-slot:item.actions="{item}">
        <td>
          <v-btn color="primary" :to="`/company_requests/${item.id}`">詳細</v-btn>
        </td>
      </template>
    </RequestDataRestTable>
  </v-container>
</template>
<script>
import SearchTable from "@/components/SearchTable";
import lodash from "lodash"
import RequestDataRestTable from "@/pages/requests/common/RequestDataRestTable.vue";

export default {
  components: {SearchTable, RequestDataRestTable},
  data() {
    return {
      debug: null,
      searchParams: {},
      lodash,
      headers: [
        {text: '申請No', value: 'id'},
        {text: '担当者', value: 'user.lastName'},
        {text: 'イベント名\n支払依頼内容', value: 'event.name'},
        {text: '開催開始日', value: 'event.startDate'},
        {text: '支払先', value: 'company.name'},
        {text: '申請状況\n申請日', value: 'statuses.inputDate'},
        {text: '内容確認状況\n内容確認日', value: 'statuses.registerDate'},
        {text: '支払予定日', value: 'statuses.scheduledPaymentDate'},
        {text: '支払日', value: 'statuses.arrangementCompletedDate'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  },
  methods: {
    search(v) {
      this.searchParams = v
    }
  }
}

</script>
