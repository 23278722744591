<template>
  <v-card class="mt-4">
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="disableServerPaging ? -1 : totalUsers"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        class="data-table">
      <!--        show-select>-->
      <template v-slot:item.actions="{item}">
        <slot name="item.actions" :item="item"></slot>
      </template>
      <!--      <template v-slot:[label(value)]="{item}" v-for="{value} in headers">-->
      <!--        <slot :name="`item.${value}`" :item="item"></slot>-->
      <!--      </template>-->

      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props"/>
      </template>

      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page-options="itemsPerPageOptions"
        >
          <template v-slot:prepend>
            <slot name="top"></slot>
          </template>
        </v-data-footer>
      </template>

    </v-data-table>
    <slot/>
  </v-card>
</template>
<script>

import axios from 'axios'

export default {
  props: {
    resource: {
      type: String
    },
    searchParams: Object,
    disableServerPaging: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      'default': [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: '会員名3',
          value: 'name',
          sortable: false,
        },
        {
          text: '会員名2',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    },
    optionsProp: Object
  },
  data() {
    return {
      value: 999,
      itemsPerPageOptions: [5, 20, 50, 100, 1000],
      items: [],
      options: this.optionsProp || {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['id'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      totalUsers: 0,
      loading: true,
    }
  },
  mounted() {
    // this.fetch()
    this.$parent.$on('search', this.fetch)
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        let params = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sort: this.sortParams
        }
        Object.assign(params, this.searchParams)
        let {data} = await axios.get('/api/' + this.resource, {
          params
        })
        this.items = data.content
        this.totalUsers = data.totalElements
      } catch (e) {
        window.bus.$emit("error", "エラーが発生しました")
        console.error(e)
      }
      this.loading = false
    },
    label(v) {
      return "item." + v
    }
  },
  computed: {
    searchParamsInner: {
      get() {
        return this.searchParams;
      },
      set(value) {
        console.log(value)
      }
    },
    sortParams() {
      let ret = []
      for (const k in this.options.sortBy) {
        ret.push(this.options.sortBy[k])
        if (this.options.sortDesc[k]) {
          ret.push('desc')
        }
      }
      return ret.join(',')
    }
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    searchParams: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
  },
}

</script>
<style>
.v-data-table-header th {
  white-space: pre-line;
}
</style>
