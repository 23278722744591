<template>
  <CRUDPage
      :searchSchema="searchSchema"
      :resultHeaders="resultHeaders"
      resource="mail_history"
      title="メール送信履歴一覧"
      schemaName="MailHistory"
  >
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";

export default {
  components: {CRUDPage},
  data() {
    return {
      searchSchema: {
        type: "object",
        "properties": {
          "requestNo": {
            "type": "string",
            "title": "申請No"
          },
          "userName": {
            "type": "string",
            "title": "宛先ユーザー"
          }
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '申請No', value: 'requestNo'},
        {text: '宛先ユーザー名', value: 'toName'},
        {text: 'メール区分', value: 'type'},
        {text: 'タイトル', value: 'title'},
        {text: '配信日時', value: 'createdAt'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
