<template>
  <CRUDPage
      :searchSchema="searchSchema"
      :resultHeaders="resultHeaders"
      resource="company"
      title="法人一覧"
      :schemaName="hasPhilipsAdmin ? 'Company' : 'CompanyLimited'"
  >
      <template v-slot:pageheader>
          <v-btn to="/api/export/companies" target="_blank" v-if="isAdmin">CSVダウンロード</v-btn>
      </template>
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import {mapGetters} from "vuex";

export default {
  components: {CRUDPage},
  computed:{
    ...mapGetters([
      'isAdmin', 'hasPhilipsAdmin'
    ]),
  },
  data() {
    return {
      searchSchema: {
        type: "object",
        "properties": {
          "name": {
            "type": "string",
            "title": "名称"
          }
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '名称', value: 'name'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
