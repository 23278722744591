import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import {en, ja} from "vuetify/es5/locale"
import VCurrencyField from 'v-currency-field'
import {VTextField} from 'vuetify/lib' //Globally import VTextField

// import colors from "vuetify/lib/util/colors"

Vue.use(Vuetify);

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
    // locale: 'ja-JP',
    decimalLength: 0,
    // // autoDecimalMode: true,
    // // min: null,
    // // max: null,
    // // defaultValue: 0,
    // valueAsInteger: true,
    // // allowNegative: true
})

export default new Vuetify({
    lang: {
        locales: {
            ja, en
        },
        current: "ja"
    },
    theme: {
        themes: {
            light: {
                primary: "#0B5ED7",//blue
                secondary: "#00A7BC",//turquoise
                accent: "#F58F08",//orange
                error: "#DF2823",//red
                info: "#992C96",//purple
                success: '#7CBD2A',//green
                warning: '#F58F08',//orange
                anchor: '#0B5ED7',
            },
            dark: {
                primary: "#000066",
                secondary: "#00545C",
                accent: "#005C1F",
                anchor: "#993300",
                error: "#5C005C"
            },
        },
    },
});

