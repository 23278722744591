<template>
  <v-container>
    <v-row>
      <v-col>
        <VJsf v-model="model" :schema="schemaInner" :options="optionsInner">
          <template v-for="(_, slot) in $slots">
            <template :slot="slot">
              <slot :name="slot"></slot>
            </template>
          </template>
          <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
            <slot :name="slot" v-bind="props"/>
          </template>

          <template v-slot:custom-money="context">
            <CustomMoney
                v-bind="context"
            />
          </template>
        </VJsf>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import CustomMoney from "@/components/requests/CustomMoney.vue";
import Rules from "@/mixins/Rules.vue";
// import VCurrencyField from 'v-currency-field'

export default {
  components: {CustomMoney},
  mixins:[Rules],
  props: {
    value: Object,
    schema: Object,
    schemaName: String,
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    options: Object
  },
  computed: {
    ...mapGetters([
      'getSchema'
    ]),
    model: {
      get() {
        return this.value || {}
      },
      set(v) {
        this.$emit("input", v)
      }
    },
    schemaInner() {
      if (this.schemaName) return this.getSchema(this.schemaName)
      return this.schema
    },
    optionsInner() {
      return Object.assign({
            rules: this.rules,
            removeAdditionalProperties: false,
            timePickerProps: {
              format: "24hr",
              "use-seconds": false
            },
            // fieldProps:{
            //   dense: true
            // }
          },
          this.options,
          this.disabled ? {
            fieldProps: {
              rules: []
            },
            disableAll: this.disabled
          } : {})
    },
  },
}
</script>