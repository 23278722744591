<template>
  <v-sheet width="260" height="70"
           min-width="260"
           class="link ma-3 col-3"
           light
           outlined
           rounded
           elevation="2"
  >
    <v-list-item :to="item.to" :href="item.href" :target="item.target" :color="item.color" light >
      <v-list-item-icon>
        <v-icon v-text="item.icon" :color="item.color"></v-icon>
      </v-list-item-icon>
      <v-list-item-content :color="item.color">
        <v-list-item-title class="link-title" v-text="item.title"></v-list-item-title>
<!--        <v-list-item-subtitle class="link-title" v-text="item.title"></v-list-item-subtitle>-->
      </v-list-item-content>
    </v-list-item>
  </v-sheet>
</template>
<script>
export default {
  props: {
    item: Object,
    color: String
  },
}
</script>

<style scoped>
.link{
  /*border: solid 10px red;*/
}
.link .col,
.link .v-list-item {
  /*max-width: 120px;*/
  /*width: 180px;*/
  /*height: 100px;*/
  flex: 0 0 25em;
}

.link .link-title {
  /*font-size: 0.8rem;*/
  white-space: break-spaces;
}
</style>
