export default {
    // fieldColProps:	{ "cols": 4 },
    // textFieldProps: {"dense": true},
    // textFieldProps:{"dense": true, "outlined":true},
    messages: {
        // required: '必須項目です',
        minLength: '{minLength}文字未満です',
        maxLength: '{maxLength}文字以上です'
    }
}
