<template>
    <v-container v-if="model">
        <v-row>
            <v-col>
                <v-autocomplete
                        clearable
                        label="透明性GLコード2" v-model="model.transparencyGlCode2" :items="code2List"
                        item-text="code2"
                        item-value="id"
                        dense
                        :disabled="!isAdmin"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-autocomplete
                        clearable
                        label="Activity-Global" v-model="model.transparencyGlActivity" :items="activities"
                        item-text="name"
                        item-value="id"
                        dense
                        :disabled="!isAdmin"
                ></v-autocomplete>
            </v-col>
            <v-col>
                <v-autocomplete
                        clearable
                        label="Expense Type-Global" v-model="model.transparencyGlExpenseType" :items="expenseTypes"
                        item-text="name"
                        item-value="id"
                        dense
                        :disabled="!isAdmin"
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field label="透明性カテゴリー名称(英語)" v-model="code2.nameEn" disabled></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field label="透明性カテゴリー名称(日本語)" v-model="code2.nameJa" disabled></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field label="透明性GLコード1" v-model="code2.code1.code1" disabled></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field label="透明性GLコード1名称" v-model="code2.code1.name" disabled></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Rules from "@/mixins/Rules";
import _ from "lodash"
import {mapGetters} from "vuex";

export default {
    mixins: [Rules],
    props: {
        value: {type: Object},
        disabled: {
            type: Boolean,
            default: false
        },
        defaultCode2: String
    },
    data() {
        return {
            glMasterList: [],
            code2List: [],
            // glMaster: null
            activityList: [],
            expenseTypeList: []
        }
    },
    computed: {
        ...mapGetters([
            'isAdmin', 'hasPhilipsAdmin'
        ]),
        model: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        },
        code2() {
            return this.code2List.find(value => {
                return value.id == this.model.transparencyGlCode2
            }) || {code1: {}}
        },
        activities() {
            return this.activityList.filter(v => {
                return (this.code2.activityGlobal || []).includes(v.id)
            })

            // return _.get(this.code2, "activityGlobal") || []
        },
        expenseTypes() {
            return this.expenseTypeList.filter(v => {
                return (this.code2.expenseTypeGlobal || []).includes(v.id)
            })
            // return _.get(this.code2, "expenseTypeGlobal") || []
        },
        requestContents() {
            return _.get(this.model, "requestContents")
        },
        transparencyGlCode2() {
            return _.get(this.model, "transparencyGlCode2")
        }
        // company() {
        //   return this.companies.find(value => value.id == this.model.companyId) || {}
        // },
        // companyId(){
        //   return this.model.companyId
        // }
    },
    watch: {
        // requestContents(v) {
        //     console.log("watch requestContents", arguments)
        //     if (v && !_.has(this.model, "transparencyGlCode2")) {
        //         let master = this.glMasterList.find(value => {
        //             return value.requestContentType == v
        //         })
        //         if (master) {
        //             this.$set(this.model, "transparencyGlCode2", master.code2.id)
        //         }
        //     }
        // },
        transparencyGlCode2() {
            console.log("watch transparencyGlCode2", this.code2)
            this.$nextTick(() => {
                console.log(JSON.stringify(this.activities), JSON.stringify(this.expenseTypes))
                if (this.activities.length) {
                    this.$set(this.model, "transparencyGlActivity", this.activities[0] && this.activities[0].id)
                }
                if (this.expenseTypes.length) {
                    this.$set(this.model, "transparencyGlExpenseType", this.expenseTypes[0] && this.expenseTypes[0].id)
                }
                if (this.code2.activityGlobalDefault) {
                    this.$set(this.model, "transparencyGlActivity", this.code2.activityGlobalDefault)
                }
                if (this.code2.expenseTypeGlobalDefault) {
                    this.$set(this.model, "transparencyGlExpenseType", this.code2.expenseTypeGlobalDefault)
                }
            })
            // this.model.transparencyGlActivity =
            // this.model.transparencyGlExpenseType = this.expenseTypes[0]
        }
    },
    methods: {
        async fetchMaster() {
            let {data} = await this.$axios.get("/api/transparency/master")
            this.glMasterList = data.content

            // if (this.model.requestContents) {
            //   // this.code2List.find(value => )
            //   this.glMaster = this.glMasterList.find(value => {
            //     value.requestContentType == this.model.requestContents
            //   })
            // }
        },
        async fetchCode2List() {
            let {data} = await this.$axios.get("/api/transparency/code2")
            this.code2List = data.content
        },
        async fetchActivities() {
            let {data} = await this.$axios.get("/api/transparency/activity")
            this.activityList = data.content
        },
        async fetchExpenseTypes() {
            let {data} = await this.$axios.get("/api/transparency/expense_type")
            this.expenseTypeList = data.content
        },
        updateGlCodes(v) {
            let master = this.glMasterList.find(value => {
                return value.requestContentType == v
            })
            if (master && master.code2) {
                this.$set(this.model, "transparencyGlCode2", master.code2.id)
            }else{
              this.$set(this.model, "transparencyGlCode2",null)
            }
        }
    },
    async mounted() {
        // await this.fetchMaster()
        // await this.fetchCode2List()
        // await this.fetchActivities()
        // await this.fetchExpenseTypes()

        await Promise.all([
            this.fetchMaster(),
            this.fetchCode2List(),
            this.fetchActivities(),
            this.fetchExpenseTypes()
        ])

        if (this.defaultCode2 && !_.has(this.model, "transparencyGlCode2")) {
            let c = this.code2List.find(value => {
                return value.code2 == this.defaultCode2
            })
            if (c) {
                this.$set(this.model, "transparencyGlCode2", c.id)
            }
        }
        window.bus.$on('onChangeRequestContents', async (v) => {
            await this.updateGlCodes(v)
        })
    }


}
</script>