<template>
  <v-container>
    <!--    {{$props}}-->
    {{ label }}
    <template v-if="value && value.key">
      <v-btn text color="primary"
             :href="value.url"
             @click:close="removeDocument">
        {{ value.fileName }}
      </v-btn>
      <v-btn @click="removeDocument" color="error"> 削除</v-btn>
    </template>
    <template v-else>
      <v-file-input show-size
                    inline
                    :label="label"
                    :disabled="disabled"
                    @change="upload"></v-file-input>
    </template>
  </v-container>
</template>
<script>
export default {
  props: {
    value: {type: Object, default: null},
    options: {type: Object, required: true},
    schema: {type: Object, required: true},
    fullSchema: {type: Object, required: true},
    fullKey: {type: String, required: true},
    label: {type: String, default: 'ファイルアップロード'},
    htmlDescription: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    rules: {type: Array, required: true},
    on: {type: Object, required: true}
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        // this.$emit("input", v)
        this.on.input(v)
      }
    },
  },
  methods: {
    async upload(file) {
      if (!file) return
      if (Array.isArray(file)) {
        this.loading = true
        try {
          for (let i in file) {
            await this.upload(file[i])
          }
        } catch (e) {
          console.error(e)
        }
        this.loading = false
        return
      }
      if (file.size > 20971520) {
        alert('ファイルサイズは20MB以下でアップロードしてください')
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await this.$axios.post('/api/file/upload', params)
      this.model = data;
    },
    removeDocument() {
      this.model = null
    },
  }
}
</script>