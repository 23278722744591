import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);

Vue.use(Vuex)

// const LOGIN_KEY = 'prev'
// let loggedInPrev = localStorage.getItem(LOGIN_KEY)
let loggedInPrev = false

export default new Vuex.Store({
    state: {
        loggedIn: !!loggedInPrev,
        me: {},
        apiDoc: null
    },
    getters: {
        loggedIn(state) {
            return state.loggedIn
        },
        me(state) {
            return _.get(state, "me.principal")
        },
        authorities(state) {
            return _.get(state, "me.authorities")
        },
        isAdmin(state) {
            return _.get(state, "me.principal.auth") == "ROLE_ADMIN"
        },
        hasPhilipsAdmin: (state, getters) => {
            return getters.has("ROLE_PHILIPS_ADMIN")
        },
        hasAccountAdmin: (state, getters) => {
            return getters.has("ROLE_ACCOUNT_ADMIN")
        },
        has: (state) => (role) => {
            return _.get(state, "me.authorities").some(v => {
                return v.authority == role
            })
        },
        apiDoc(state) {
            return state.apiDoc
        },
        getSchema: (state, getters) => (name, stack) => {
            console.log("getSchema", name)
            stack = (stack || []).concat()
            if (stack.filter(value => value == name).length > 0) return null
            stack.push(name)
            let schema = _.get(state.apiDoc, `components.schemas[${name}]`, {})
            _.eachDeep(schema, (value, key, parent) => {
                if (_.has(value, "$ref")) {
                    let cname = _.get(value, "$ref").match(/[^¥/]*$/g)
                    if (cname[0]) {
                        let childSchema = getters.getSchema(cname[0], stack)
                        if (childSchema) parent[key] = childSchema
                    }
                }
                if (key == "x-const") {
                    parent["const"] = value
                }
                if (key == "items" && _.has(parent, "items.oneOf")) {
                    _.set(parent, "items.type", "object")
                }
                if (key == "required" && _.has(parent, "allOf")) {
                    parent.allOf.some((value1, index) => {
                        if (_.has(value1, "type") && _.get(value1, "type") == "object") {
                            _.set(parent, `allOf[${index}].required`, value)
                            return 1
                        }
                    })
                }
                if (key == "allOf") {
                    value.forEach((v) => {
                        if (_.has(v, "properties.type")) {
                            let constKey = _.get(v, "properties.type")
                            _.set(constKey, "const", _.get(constKey, "x-const"))
                            _.set(parent, "properties.type", constKey)
                        }
                    })
                }
            }, {
                checkCircular: true,
            })
            console.log("getSchemaEnd", schema)
            return schema;
        },
        vjsfOption() {
            return {
                // fieldColProps:	{ "cols": 4 }

                fieldColProps: {"dense": true}
            }
        }
    },
    mutations: {
        login(state, me) {
            state.loggedIn = true
            state.me = me
            // localStorage.setItem(LOGIN_KEY, true)
        },
        logout(state) {
            state.loggedIn = false
            state.me = null
            // localStorage.removeItem(LOGIN_KEY)
        },
        setApiDoc(state, data) {
            state.apiDoc = data
        },
    },
    actions: {
        async login({commit, dispatch}, {username, password}) {
            try {
                let form = new FormData()
                form.set('username', username)
                form.set('password', password)
                let {data} = await axios.post('/auth/login', form)
                console.log(data)
                console.log(dispatch)
                await dispatch('getMe')
                return true
            } catch (err) {
                console.error(err)
                commit('logout')
                alert('メールアドレスまたはパスワードが間違っています。')
                return false
            }
        },
        async logout({commit}) {
            console.log("logout")
            await axios.get("/auth/logout")
            commit('logout')
        },
        async getMe({commit}) {
            try {
                let {data} = await axios.get('/api/me')
                commit('login', data)
            } catch (e) {
                commit('logout')
            }
        },
        async getApiDoc({commit}) {
            try {
                let {data} = await axios.get('/v3/api-docs/')
                commit('setApiDoc', data)
            } catch (e) {
                commit('setApiDoc', null)
            }
        }
    },
    modules: {}
})
