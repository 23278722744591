<template>
  <v-container>
    <v-card class="align-center ma-10 pa-10">

      <v-card-text class="text-pre">{{message}}</v-card-text>

      <v-form v-model="valid" @submit.prevent="signIn">
        <v-text-field
            v-model="username"
            label="ログインID"
            required
        ></v-text-field>
        <v-text-field
            v-model="password"
            label="パスワード"
            required
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
        ></v-text-field>
        <!--    <v-checkbox-->
        <!--        :label="`ログイン状態を保持`"-->
        <!--        v-model="remember"-->
        <!--        style="padding:0px;margin:0px;"-->
        <!--    ></v-checkbox>-->
        <v-btn type="submit" depressed large color="primary">ログイン</v-btn>

      </v-form>
      <v-card-actions>
      </v-card-actions>
    </v-card>
    <v-container class="text-center">
      <v-row>
        <v-col cols="12">
          <v-btn to="/password_reset" plain>パスワードを忘れた方はこちら</v-btn>
        </v-col>
<!--        <v-col cols="12">-->
<!--          <v-btn to="/signup">新規作成</v-btn>-->
<!--        </v-col>-->
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      username: undefined,
      password: undefined,
      showPassword: false,
      valid: false,
      message: null
    }
  },
  computed: {
    ...mapGetters([
      'loggedIn', 'me'
    ]),
  },
  created() {
    this.fetchNotify()
  },
  methods: {
    async signIn() {
      let ret = await this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      })
      if (ret) {
        // location.reload()
        // this.loginDialog = false
        this.$nextTick(() => {
          this.$router.push({path: '/home'})
        })
      }
    },
    onLogin() {
      console.log("onlogin")
    },
    async fetchNotify(){
      let {data} = await this.$axios.get("/auth/loginNotify")
      this.message = data

    }
  }
}
</script>