<template>
  <CRUDPage
      :resultHeaders="resultHeaders"
      resource="info"
      title="お知らせ配信一覧"
      schemaName="Info"
      :readonly="readonly"
      :id="id"
  >
    <template v-slot:item.sendDateTime="{item}">
      {{ item.sendDateTime | format }}
    </template>
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";

export default {
  props: {
    id: Number,
    readonly: Boolean,
  },
  components: {CRUDPage},
  data() {
    return {
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '配信日時', value: 'sendDateTime'},
        {text: 'タイトル', value: 'title'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  },
  filters: {
    format(v) {
      return new Date(v).toLocaleString()
    }
  }
}
</script>