<script>
import FormWrapper from "@/components/requests/FormWrapper.vue";

export default {
  mixins:[FormWrapper],
  data(){
    return {
      taxRates: []
    }
  },
  computed:{

  },
  watch:{

  },
  created(){
    this.fetch()
  },
  methods:{
    async fetch(){
      let {data} = await this.$axios.get("/api/taxrate")
      this.taxRates = data
    }
  }
}
</script>