<template>
  <CRUDPage
      :searchSchema="searchSchema"
      :resultHeaders="resultHeaders"
      resource="cost_center"
      title="コストセンターマスター一覧"
      schemaName="CostCenterBody"
  >
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";

export default {
  components: {CRUDPage},
  data() {
    return {
      searchSchema: {
        type: "object",
        "properties": {
          "no": {
            "type": "string",
            "title": "#(ナンバー)",
            "x-cols": 6
          },
          "name": {
            "type": "string",
            "title": "名称",
            "x-cols": 6
          },
          "department": {
            "type": "string",
            "title": "管理部署",
            "x-fromUrl": "/api/department",
            'x-itemsProp': 'content',
            'x-itemTitle': 'name',
            'x-itemKey': 'id'
          },
          "user": {
            "type": "string",
            "title": "担当者",
            "x-cols": 6
          },
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: 'コストセンターNo', value: 'no'},
        {text: 'コストセンター名称', value: 'name'},
        {text: '担当者', value: 'user.name'},
        {text: '管理部署', value: 'user.department.name'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
