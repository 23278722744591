<template>
  <CRUDPage
      :searchSchema="searchSchema"
      :resultHeaders="resultHeaders"
      resource="event"
      title="イベント一覧"
      schemaName="EventDto"
  >
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";

export default {
  components: {CRUDPage},
  data() {
    return {
      searchSchema: {
        type: "object",
        "properties": {
          "name": {
            "type": "string",
            "title": "名称",
            "x-cols": 6
          },
          "startDate": {
            "type": "string",
            "title": "開催開始日",
            "format": "date",
            "x-cols": 6
          },
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '名称', value: 'name'},
        {text: '開催開始日', value: 'startDate'},
        {text: '開催日数', value: 'dateCount'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
