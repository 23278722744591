var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',[_vm._v("招聘状出力")]),_c('VJsf',{attrs:{"schema":{
              type: "object",
              "properties": {
                "template": {
                  "type": "string",
                  "title": "招聘状",
                  "x-fromUrl": "/api/letters/templates",
                  "x-itemKey": "value",
                  "x-itemTitle": "label"
                }
              }
      }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('div',{staticClass:"pa-5",staticStyle:{"border":"solid 1px","width":"auto","display":"inline-block"}},[_c('iframe',{ref:"program_frame",staticStyle:{"height":"1800px"},attrs:{"width":"840px","height":"1800px","scrolling":"no","frameborder":"0","srcdoc":_vm.srcdoc}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }