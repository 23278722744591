<template>
  <v-container>
    <FormWrapper v-model="model" :schema='arraySchema' :options="{ editMode: 'inline',fieldColProps: {dense : true}}" class="travel-details"></FormWrapper>
    <v-currency-field readonly v-model="totalAmount" :label="isOther ? 'B.その他立替金合計' : 'A.交通費合計'"
                  class="mt-5"></v-currency-field>

    <v-textarea filled v-model="model.otherRemarks" label="その他立替金備考" v-if="this.isOther"></v-textarea>
    <v-textarea filled v-model="model.remarks" label="交通費備考" v-else></v-textarea>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import FormWrapper from "@/components/requests/FormWrapper.vue";

const itemSchemaA = {
  "type": "object",
  "properties": {
    "date": {
      "type": "string",
      "title": "年月日",
      "format": "date",
      "x-cols": 3
    },
    "name": {
      "type": "string",
      "title": "交通手段",
      "x-cols": 2
    },
    "from": {
      "type": "string",
      "title": "区間・自",
      "x-cols": 1.5
    },
    "to": {
      "type": "string",
      "title": "区間・至",
      "x-cols": 1.5
    },
    "hasReceipt": {
      "type": "boolean",
      "title": "領収書有",
      "x-cols": 2
    },
    "amount": {
      "type": "number",
      "title": "金額",
      "x-display": "custom-money",
      "x-cols": 2
    }
  }
}

const itemSchemaB = {
  "type": "object",
  "properties": {
    "date": {
      "type": "string",
      "title": "年月日",
      "format": "date",
      "x-cols": 3
    },
    "name": {
      "type": "string",
      "title": "立替内容詳細",
      "x-cols": 3
    },
    "hasReceipt": {
      "type": "boolean",
      "title": "領収書有",
      "x-cols": 3
    },
    "amount": {
      "type": "number",
      "title": "金額",
      "x-display": "custom-money",
      "x-cols": 3
    }
  }
}

export default {
  components: {FormWrapper},
  props: {
    value: {
      type: Object, default: () => {
        return {}
      }
    },
    totalLabel: String,
    isOther: {
      type: Boolean,
      "default": false
    }
  },
  data() {
    return {
      itemSchemaA,
      itemSchemaB
    }
  },
  computed: {
    arraySchema() {
      let name = this.isOther ? "travelOtherDetails" : "travelDetails"
      let properties = {}
      properties[name] = {
        "type": "array",
        "title": "行を追加する",
        "items": this.isOther ? itemSchemaB : itemSchemaA
      }
      return {
        "type": "object",
        "properties": properties
      }

      // return {
      //       "type": "array",
      //       "title": "行を追加する",
      //       "items": this.isOther ? itemSchemaB : itemSchemaA
      // }
    },
    arrays() {
      return this.model[this.isOther ? "travelOtherDetails" : "travelDetails"]
    },
    ...mapGetters([
      'apiDoc', 'vjsfOption'
    ]),
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    travelDetails() {
      return this.model.travelDetails
    },
    totalAmount() {
      return Array.isArray(this.arrays) ?
          this.arrays.reduce((sum, v) => {
            return (Number.isInteger(v.amount)) ? sum + v.amount : sum
          }, 0) : 0
    }
  }
}
</script>
<style>
.travel-details .v-alert--outlined {
  border: none !important;
}

.travel-details .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>