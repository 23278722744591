import Vue from 'vue'
import Router from 'vue-router'
// import Meta from 'vue-meta'
import Home from "@/pages/Home";
import CompanyRequests from "@/pages/requests/company/CompanyRequests";
// import CompanyRequestsAdd from "@/pages/requests/company/CompanyRequestsAdd";
import CompanyRequestsDetail from "@/pages/requests/company/CompanyRequestsDetail";
import KolRequests from "@/pages/requests/kol/KolRequests";
import PurchaseOrderPage from "@/pages/master/PurchaseOrderPage";
import UsersPage from "@/pages/master/UsersPage";
import EventsPage from "@/pages/master/EventsPage";
import CompaniesPage from "@/pages/master/CompaniesPage";
import CostCentersPage from "@/pages/master/CostCentersPage";
import DepartmentsPage from "@/pages/master/DepartmentsPage";
import KolsPage from "@/pages/master/KolsPage";
import InfosPage from "@/pages/master/InfosPage";
import Login from "@/pages/Login";
// import KolRequestsAdd from "@/pages/requests/kol/KolRequestsAdd";
import KolRequestsDetail from "@/pages/requests/kol/KolRequestsDetail";
import store from './store'
import TransportCostAbroadRequests from "@/pages/requests/transport_cost_abroad/TransportCostAbroadRequests";
import TransportCostRequests from "@/pages/requests/transport_cost/TransportCostRequests";
import TravelCostRequests from "@/pages/requests/travel_cost/TravelCostRequests";
// import TransportCostRequestsAdd from "@/pages/requests/transport_cost/TransportCostRequestsAdd";
import TransportCostRequestsDetail from "@/pages/requests/transport_cost/TransportCostRequestsDetail";
// import TravelCostRequestsAdd from "@/pages/requests/travel_cost/TravelCostRequestsAdd";
import TravelCostRequestsDetail from "@/pages/requests/travel_cost/TravelCostRequestsDetail";
// import TransportCostAbroadRequestsAdd from "@/pages/requests/transport_cost_abroad/TransportCostAbroadRequestsAdd";
import TransportCostAbroadRequestsDetail
    from "@/pages/requests/transport_cost_abroad/TransportCostAbroadRequestsDetail";
import HolidayPage from "@/pages/master/HolidayPage.vue";
import Invoice from "@/pages/Invoice.vue";
import MailHistory from "@/pages/master/MailHistory.vue";
import MailTemplate from "@/pages/master/MailTemplate.vue";
import Letters from "@/pages/Letters.vue";
import SignUp from "@/pages/SignUp.vue";
import PasswordReset from "@/pages/PasswordReset.vue";
import ServiceParamsPage from "@/pages/master/ServiceParamsPage.vue";
import DocEvents from "@/pages/doc_events/DocEvents.vue";
import DocEventDetail from "@/pages/doc_events/DocEventDetail.vue";
import DocGuest from "@/pages/doc_events/DocGuest.vue";
import DocEvent from "@/pages/doc_events/DocEvent.vue";
import DocSessionDetail from "@/pages/doc_events/DocSessionDetail.vue";
import TransparencyGL from "@/pages/master/transparency/TransparencyGL.vue";
import TransparencyGLCode1 from "@/pages/master/transparency/TransparencyGLCode1.vue";
import TransparencyGLActivity from "@/pages/master/transparency/TransparencyGLActivity.vue";
import TransparencyGLExpense from "@/pages/master/transparency/TransparencyGLExpense.vue";
import TransparencyGLCode2 from "@/pages/master/transparency/TransparencyGLCode2.vue";
import CsvDownloads from "@/pages/CsvDownloads.vue";

Vue.use(Router)
// Vue.use(Meta)


let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/', component: Home,
            meta: {requiresAuth: true}
        },
        {path: '/signup', component: SignUp},
        {path: '/password_reset', component: PasswordReset},
        {path: '/password_reset/:token', component: PasswordReset, props: true},
        {path: '/home', redirect: "/"},
        {path: '/info', component: InfosPage, meta: {requiresAuth: true}, props: {readonly: true}},
        {
            path: '/info/:id', component: InfosPage, meta: {requiresAuth: true}, props: route => ({
                id: route.params.id,
                readonly: true
            })
        },

        {path: '/company_requests', component: CompanyRequests, meta: {requiresAuth: true},},
        {path: '/company_requests/add', component: CompanyRequestsDetail, meta: {requiresAuth: true},},
        {path: '/company_requests/:id', component: CompanyRequestsDetail, props: true, meta: {requiresAuth: true},},

        {path: '/kol_requests', component: KolRequests, meta: {requiresAuth: true},},
        {path: '/kol_requests/add', component: KolRequestsDetail, meta: {requiresAuth: true},},
        {path: '/kol_requests/:id', component: KolRequestsDetail, props: true, meta: {requiresAuth: true},},

        {path: '/travel_cost_requests', component: TravelCostRequests, meta: {requiresAuth: true},},
        {path: '/travel_cost_requests/add', component: TravelCostRequestsDetail, meta: {requiresAuth: true},},
        {
            path: '/travel_cost_requests/:id',
            component: TravelCostRequestsDetail,
            props: true,
            meta: {requiresAuth: true},
        },

        {path: '/transport_cost_requests', component: TransportCostRequests, meta: {requiresAuth: true},},
        {path: '/transport_cost_requests/add', component: TransportCostRequestsDetail, meta: {requiresAuth: true},},
        {
            path: '/transport_cost_requests/:id',
            component: TransportCostRequestsDetail,
            props: true,
            meta: {requiresAuth: true},
        },

        {path: '/transport_cost_abroad_requests', component: TransportCostAbroadRequests, meta: {requiresAuth: true},},
        {
            path: '/transport_cost_abroad_requests/add',
            component: TransportCostAbroadRequestsDetail,
            meta: {requiresAuth: true},
        },
        {
            path: '/transport_cost_abroad_requests/:id',
            component: TransportCostAbroadRequestsDetail,
            props: true,
            meta: {requiresAuth: true},
        },

        {path: "/export", name: "export", component: CsvDownloads, meta: {requiresAuth: true}},

        {path: '/cost_center', component: CostCentersPage, meta: {requiresAuth: true},},
        {path: '/po', component: PurchaseOrderPage, meta: {requiresAuth: true},},
        {path: '/event', component: EventsPage, meta: {requiresAuth: true},},
        {path: '/kol', component: KolsPage, meta: {requiresAuth: true},},
        {path: '/company', component: CompaniesPage, meta: {requiresAuth: true},},
        {path: '/user', component: UsersPage, meta: {requiresAuth: true},},

        {path: '/department', component: DepartmentsPage, meta: {requiresAuth: true},},
        {path: '/info_man', component: InfosPage, meta: {requiresAuth: true}},
        {path: '/holiday', component: HolidayPage, meta: {requiresAuth: true},},
        {path: '/mail_history', component: MailHistory, meta: {requiresAuth: true},},
        {path: '/mail_template', component: MailTemplate, meta: {requiresAuth: true},},
        {path: '/service', component: ServiceParamsPage, meta: {requiresAuth: true},},


        {path: '/transparency/master', component: TransparencyGL, meta: {requiresAuth: true},},
        {path: '/transparency/code1', component: TransparencyGLCode1, meta: {requiresAuth: true},},
        {path: '/transparency/code2', component: TransparencyGLCode2, meta: {requiresAuth: true},},
        {path: '/transparency/activity', component: TransparencyGLActivity, meta: {requiresAuth: true},},
        {path: '/transparency/expense_type', component: TransparencyGLExpense, meta: {requiresAuth: true},},


        {
            path: '/invoice', component: Invoice, meta: {requiresAuth: true}, props: route => ({
                poId: route.query.poId,
                userId: route.query.userId,
                yearMonth: route.query.yearMonth
            })
        },
        {path: '/letter', component: Letters, meta: {requiresAuth: true},},

        {path: "/doc_event", component: DocEvents, meta: {requiresAuth: true},},
        {
            path: "/doc_event/:eventId", component: DocEvent, props: true, meta: {requiresAuth: true},
        },
        {
            path: "/doc_event/:eventId/edit", component: DocEventDetail, props: true, meta: {requiresAuth: true},
        },
        {
            path: "/doc_event/:eventId/session/:sessionId", component: DocSessionDetail, props: true, meta: {requiresAuth: true},
        },
        {
            path: "/doc_event/:eventId/session/:sessionId/guest/:guestId", component: DocGuest, props: true, meta: {requiresAuth: true},
        },

        {path: '/login', component: Login}
    ]
})

router.beforeEach(async (to, from, next) => {
    console.log("beforeEach store.state.loggedIn", store.state.loggedIn)
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.loggedIn) {

        await store.dispatch('getMe');

        if (!store.state.loggedIn) {
            next("/login")
        } else {
            next()
        }

    } else {
        next()
    }
})

export default router