<template>
  <v-container v-if="model" class="transport-arrangements">
    <FormWrapper v-model="model" :schema="schema" class="travel-arrangement-details"
          :options="options"
    ></FormWrapper>
  </v-container>
</template>
<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import dayjs from "dayjs";
import FormWrapper from "@/components/requests/FormWrapper.vue";
import {mapGetters} from "vuex";

const _ = deepdash(lodash);

export default {
  components:{
    FormWrapper
  },
  props: {
    value: {type: Object},
    isAbroad: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getSchema'
    ]),
    model: {
      get() {
        return this.value || {}
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    schema(){
      let schema = this.getSchema("TransportCostArrangementDetails")
      if(!this.isAbroad) return schema;
      _.eachDeep(schema, (value, key, parent) => {
        if (key == "departurePrefecture" || key == "destinationPrefecture") {
          delete parent[key]
        }
        if (key == "departureDetail" || key == "destinationDetail") {
          _.set(value, "x-cols", 6)
        }
        if (key == "departureCity" || key == "destinationCity") {
          _.set(value, "title", key == "departureCity" ? "出発地" : "滞在地")
          _.set(value, "x-cols", 6)
        }
      }, {
        checkCircular: true,
      })
      return schema;
    },
    options() {
      return {
        dialogProps: {"maxWidth": 800},
        rules:{
          even: function(e){return!e||e%2==0||"Only even numbers accepted"},
          millageNumber(e){
            return !e || []
          }
        },
        context:{
          isAbroad: this.isAbroad,
          isNotAbroad: !this.isAbroad,
        }
      }
    },
    transportDetails(){
      return _.get(this.model, "transportDetails.transportDetails", [])
    },
    hotelDetails() {
      return _.get(this.model, "hotelDetails.hotelDetails", [])
    },
    taxiDetails() {
      return _.get(this.model, "taxiDetails", {})
    }
  },
  watch: {
    hotelDetails: {
      depp: true,
      handler() {
        (this.hotelDetails || []).forEach(v => {
          if (v.checkInDate && v.stays) {
            let ret = dayjs(v.checkInDate).add(v.stays, "day").format('YYYY-MM-DD')
            this.$set(v, "checkOutDate", ret)
          }
        })
      }
    },
    taxiDetails: {
      depp: true,
      handler() {
        let ret = (this.taxiDetails.tickets || []).reduce((sum, v) => {
          return (Number.isInteger(v.amount)) ? sum + v.amount : sum
        }, 0)
        this.$set(this.taxiDetails, "total", ret)
      }
    }
  }
}
</script>
<style>
.travel-arrangement-details .v-alert--outlined {
  border: none !important;
}

.travel-arrangement-details .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.theme--light.v-label--is-disabled {
  color: rgba(0, 0, 0, 0.6);
}

.theme--light.v-radio--is-disabled label {
  color: rgba(0, 0, 0, 0.6);
}

.transport-arrangements .v-input {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>