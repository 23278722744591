<template>
  <RequestsDetail resource="travel_cost" listPage="travel_cost_requests" v-model="model" :id="id">
    <template v-slot:default="{isNew, isAdmin}">
      <h1>交通費立替精算申請</h1>

      <TravelCostRequestsForm v-model="model"  :disabled="!isNew && !isAdmin" :detail="!isNew"></TravelCostRequestsForm>

      <LetterPackForm v-model="model" :disabled="!isAdmin" v-if="!isNew" />

      <TKPExclusiveForm v-model="model" :disabled="!isNew &&!isAdmin" v-if="!isNew"></TKPExclusiveForm>
    </template>
  </RequestsDetail>
</template>
<script>
import RequestsDetail from "@/pages/requests/common/RequestsDetail.vue";
import TKPExclusiveForm from "@/components/requests/TKPExclusiveForm.vue";
import TravelCostRequestsForm from "@/pages/requests/travel_cost/TravelCostRequestsForm.vue";
import LetterPackForm from "@/components/requests/LetterPackForm.vue";

export default {
  props: {
    id: String,
  },
  data() {
    return {
      model: {}
    }
  },
  components: {
    TravelCostRequestsForm,
    TKPExclusiveForm,
    RequestsDetail,
    LetterPackForm
  },
}
</script>
