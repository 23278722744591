<template>
  <RequestsDetail resource="kol" listPage="kol_requests" v-model="model" :id="id">
    <template v-slot:default="{isNew, isAdmin}">
      <h1>お客様支払依頼申請</h1>

      <KolRequestsForm v-model="model" :disabled="!isNew && !isAdmin" :detail="!isNew">
      </KolRequestsForm>

      <LetterPackForm v-model="model" :disabled="!isAdmin" v-if="!isNew" />

      <TKPExclusiveForm v-model="model" :disabled="!isNew &&!isAdmin" v-if="!isNew"></TKPExclusiveForm>
    </template>
  </RequestsDetail>
</template>
<script>
import RequestsDetail from "@/pages/requests/common/RequestsDetail.vue";
import TKPExclusiveForm from "@/components/requests/TKPExclusiveForm.vue";
import LetterPackForm from "@/components/requests/LetterPackForm.vue";
import KolRequestsForm from "@/pages/requests/kol/KolRequestsForm.vue";

export default {
  props: {
    id: String,
  },
  data() {
    return {
      model: {}
    }
  },
  components: {
    KolRequestsForm,
    LetterPackForm,
    TKPExclusiveForm,
    RequestsDetail,
  },
}
</script>

