<template>
  <CRUDPage
      :searchSchemaaaa="searchSchema"
      searchSchemaName="PoSearchParam"
      :resultHeaders="resultHeaders"
      resource="po"
      title="PO番号一覧"
      schemaName="PurchaseOrderBody"
      @select="getBudget"
  >
    <template slot="remarks-after">
      <div v-if="has('ROLE_COST_CENTER_ADMIN')">
        PO番号予算詳細
        <v-simple-table v-if="showBudget">
          <template v-slot:default>
            <tbody class="text-left">
            <tr>
              <td>予算額</td>
              <td>{{ budget.budget.toLocaleString() }}円</td>
            </tr>
            <tr>
              <td>法人宛支払依頼申請の合計額（税別）</td>
              <td>{{ budget.companyRequestBudget.toLocaleString() }}円({{ budget.companyRequestSize }}件)</td>
            </tr>
            <tr>
              <td>お客様支払依頼申請の合計額（税別）</td>
              <td>{{ budget.kolRequestBudget.toLocaleString() }}円({{ budget.kolRequestSize }}件)</td>
            </tr>
            <tr>
              <td>交通費立替精算申請の合計額（税別）</td>
              <td>{{ budget.travelCostRequestBudget.toLocaleString() }}円({{ budget.travelCostRequestSize }}件)</td>
            </tr>
            <tr>
              <td>残予算額</td>
              <td>{{ budget.remainingBudget.toLocaleString() }}円</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </template>
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import {mapGetters} from "vuex";

export default {
  components: {CRUDPage},
  computed: {
    ...mapGetters([
      'has'
    ]),
  },
  data() {
    return {
      budget: undefined,
      showBudget: false,
      searchSchema: {
        type: "object",
        "properties": {
          "no": {
            "type": "string",
            "title": "No",
            "x-cols": 6
          },
          "costCenter": {
            "type": "string",
            "title": "コストセンター",
            "x-cols": 6
          },
          "poDeadlineDate": {
            "type": "string",
            "title": "PO利用期限",
            "format": "date",
            "x-cols": 6
          },
        }
      },
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: 'コストセンター', value: 'costCenter.name'},
        {text: 'No', value: 'no'},
        {text: '予算区分', value: 'budgetType'},
        {text: 'PO利用期限', value: 'poDeadlineDate'},
        {text: '依頼期限日', value: 'requestDeadlineDate'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  },
  methods: {
    async getBudget(id) {
      if(!this.has('ROLE_COST_CENTER_ADMIN')){
        return;
      }
      this.showBudget = false
      try {
        let {data} = await this.$axios.get(`/api/po/budget/${id}`)
        this.budget = data
        this.showBudget = true
        this.$forceUpdate()
      } catch (e) {
        // window.bus.$emit('error', '指定した内容は見つかりません')
      }
    }
  }
}
</script>
