<template>
  <CRUDPage
      :resultHeaders="resultHeaders"
      resource="transparency/code1"
      title="透明性GLコード1一覧"
      schemaName="TransparencyGlCode1"
      :searchParamsProp="{sort: 'code1'}"
  >
    <template v-slot:pageheader>
      <TransparencyMenu></TransparencyMenu>
    </template>
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import TransparencyMenu from "@/pages/master/transparency/TransparencyMenu.vue";

export default {
  components: {TransparencyMenu, CRUDPage},
  data() {
    return {
      resultHeaders: [
        {text: 'code', value: 'code1'},
        {text: '名称', value: 'name', sortable: false},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
