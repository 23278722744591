<template>
  <CRUDPage
      searchSchemaName="KolSearchParam"
      :resultHeaders="resultHeaders"
      resource="kol"
      title="お客様一覧"
      :schemaName="hasPhilipsAdmin ? 'Kol' : 'KolLimited'"
  >
      <template v-slot:pageheader>
          <v-btn to="/api/export/kols" target="_blank"  v-if="isAdmin">CSVダウンロード</v-btn>
      </template>
    <template v-slot:item.isRegisteredBankAccount="{item}">
      <td>
        <v-chip v-if="item.registeredBankAccount" dark color="blue" label x-small>登録済み</v-chip>
        <v-chip v-else dark color="red" label x-small>未登録</v-chip>
        <br/>
        <v-chip v-if="item.isRegisteredMyNumber == '登録済み'" dark color="blue" label x-small>登録済み</v-chip>
        <v-chip v-else dark color="red" label x-small>未登録</v-chip>
      </td>
    </template>
    <template v-slot:item.invoice="{item}">
      <td>
        {{item.invoice || 0}}円
        <br/>
        0円
      </td>
    </template>
  </CRUDPage>
</template>
<script>
import CRUDPage from "@/components/CRUDPage";
import {mapGetters} from "vuex";

export default {
  components: {CRUDPage},
  computed:{
    ...mapGetters([
      'isAdmin', 'hasPhilipsAdmin'
    ]),
  },
  data() {
    return {
      resultHeaders: [
        {text: 'No', value: 'id'},
        {text: '氏名', value: 'name'},
        {text: '氏名カナ', value: 'nameKana'},
        {text: '所属先施設名', value: 'affiliation'},
        {text: '口座情報\nマイナンバー', value: 'isRegisteredBankAccount'},
        {text: '支払総額(源泉徴収別)\n源泉徴収税合計', value: 'invoice'},
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
    }
  }
}
</script>
