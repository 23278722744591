<template>
  <v-container>
    <v-sheet outlined class="mt-4 pa-4">
      <h4 class="accent pa-2">交通手配（ご確定情報）</h4>
      <FormWrapper v-model="model.confirmTransport" schemaName="TransportCostConfirmTransport"
        :options="options"
      >
        <template v-slot:custom-file="context">
          <template v-if="context.modelKey == 'planeDoc'">
            <CustomFile v-bind="context" label="飛行機"></CustomFile>
          </template>
          <template v-if="context.modelKey == 'shinkansenDoc'">
            <CustomFile v-bind="context" label="新幹線"></CustomFile>
          </template>
          <template v-if="context.modelKey == 'trainDoc'">
            <CustomFile v-bind="context" label="電車"></CustomFile>
          </template>
        </template>
      </FormWrapper>
    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <h4 class="accent pa-2">宿泊手配（ご確定情報）</h4>
      <FormWrapper v-model="model.confirmHotel" schemaName="TransportCostConfirmHotel"></FormWrapper>
    </v-sheet>
    <v-sheet outlined class="mt-4 pa-4">
      <h4 class="accent pa-2">タクシーチケット（ご確定情報）</h4>
      <FormWrapper v-model="model.confirmTaxi" schemaName="TransportCostConfirmTaxi">
        <template v-slot:custom-month="context">
          <CustomMonth v-bind="context" ></CustomMonth>
        </template>
      </FormWrapper>
    </v-sheet>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import FormWrapper from "@/components/requests/FormWrapper.vue";
import CustomFile from "@/components/requests/CustomFile.vue";
import CustomMonth from "@/components/requests/CustomMonth.vue";

export default {
  components: {
    CustomFile,
    CustomMonth,
    FormWrapper
  },
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters([
      'getSchema'
    ]),
    model: {
      get() {
        return this.value || {}
      },
      set(v) {
        this.$emit("input", v)
      }
    },
    options(){
      return {
        context:{
          // routeDetails: [{},{}]
        }
      }
    }
  },
  methods: {
    async upload(file) {
      if (!file) return
      if (Array.isArray(file)) {
        this.loading = true
        try {
          for (let i in file) {
            await this.upload(file[i])
          }
        } catch (e) {
          console.error(e)
        }
        this.loading = false
        return
      }
      if (file.size > 20971520) {
        alert('ファイルサイズは20MB以下でアップロードしてください')
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await this.$axios.post('/api/file/upload', params)
      let documents = this.model.files || []
      documents.push(data)
      this.$set(this.model, "files", documents)
    },
    removeDocument(item) {
      this.model.files = this.model.files.filter(v => v.key != item.key)
    },
  }
}
</script>